import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Product, ProductCategory } from 'src/app/models/product';
import { SimpleProduct } from '../input-product-search/input-product-search.component';
import { expandCollapse } from 'src/app/animations/expand-collapse.animation';
import { rotate180 } from 'src/app/animations/rotate-180.animation';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { ProductsService } from 'src/app/services/products.service';
import { ShoppingList } from 'src/app/models/shopping-list';
import { EMPTY, Observable, map, of, tap, timer } from 'rxjs';

@Component({
    selector: 'app-day-shopping-list',
    templateUrl: './day-shopping-list.component.html',
    styleUrls: ['./day-shopping-list.component.scss'],
    animations: [expandCollapse, rotate180],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayShoppingListComponent implements OnInit {
    @Input() dayIndex = -1;
    @Input() currOpenDayIndex = -1;
    @Input() dayShoppingList: Product[] = [];

    @Input() isExpandProductsContainer = true;
    @Output() toggleDay = new EventEmitter<number>();

    weekDays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
    dayName?: string;
    isUpdateProduct = false;
    selectedUpdateProduct?: Product | null;
    currUpdateIndex: number | null = null;
    currDeleteIndex: number | null = null;

    productList$ = new Observable<Product[]>();

    constructor(
        private shoppingCartService: ShoppingCartService,
        private productsService: ProductsService
    ) {
        this.productList$ = this.shoppingCartService.getSelectedShoppingList().pipe(
            map((shoppingList: ShoppingList | null) => {
                if (shoppingList?.productList) {
                    return shoppingList.productList;
                } else if (shoppingList?.weekProductList) {
                    return shoppingList.weekProductList[this.dayIndex];
                } else {
                    return [];
                }
            })
        );
    }

    ngOnInit(): void {
        this.dayName = this.weekDays[this.dayIndex];
    }

    ngOnChanges(changes: SimpleChanges): void {
        let currOpenIndex: number = changes['currOpenDayIndex']?.currentValue;
        if (currOpenIndex !== undefined && this.dayIndex !== currOpenIndex) {
            this.isExpandProductsContainer = false;
        }
    }

    toggleUpdateProduct(product: Product, updateIndex: number) {
        if (updateIndex !== this.currUpdateIndex) {
            this.isUpdateProduct = true;
            this.selectedUpdateProduct = product;
            this.currUpdateIndex = updateIndex;
        } else {
            this.isUpdateProduct = false;
            this.selectedUpdateProduct = null;
            this.currUpdateIndex = null;
        }
    }

    deleteProduct(product: Product, productIndex: number) {
        this.currDeleteIndex = productIndex;
        const productId = product.id;
        timer(250).subscribe(() => {
            this.currDeleteIndex = null;
            this.shoppingCartService.deleteProduct(productId, this.dayIndex);
        });
    }

    onAddProduct(addedProduct: SimpleProduct): void {
        if (addedProduct && addedProduct.id && addedProduct.c) {
            //Check product already exist in shoppinglist, so we just raise up the product cnt
            if (this.checkProductAlreadyExistInShoppingList(addedProduct)) {
                this.updateExistProduct(addedProduct);
            } else {
                this.addNewProduct(addedProduct);
            }

            this.resetUpdateProduct();
        }
    }

    onBlurProductUpdate(): void {
        this.resetUpdateProduct();
    }

    toggleShoppingList(): void {
        if (this.isExpandProductsContainer) {
            this.isExpandProductsContainer = false;
        } else {
            this.isExpandProductsContainer = true;
            this.toggleDay.emit(this.dayIndex);
        }
    }

    private resetUpdateProduct(): void {
        this.isUpdateProduct = false;
        this.selectedUpdateProduct = null;
        this.currUpdateIndex = null;
    }

    private checkProductAlreadyExistInShoppingList(newProduc: SimpleProduct): boolean {
        const findProduct = this.dayShoppingList.find((shoppingProduct: Product) => shoppingProduct.id === newProduc.id) !== undefined;
        return findProduct;
    }

    private updateExistProduct(addedProduct: SimpleProduct): void {
        this.shoppingCartService.updateProduct(addedProduct, this.isUpdateProduct);
        this.isUpdateProduct = false;
    }

    private addNewProduct(addedProduct: SimpleProduct): void {
        if (addedProduct && addedProduct.id && addedProduct.c) {
            this.shoppingCartService.addProductByIdAndDayIndex(addedProduct.id, this.dayIndex, addedProduct.c).subscribe((product: Product) => {
                if (product) {
                    //  this.dayShoppingList.unshift(product);
                }
            });
        }
    }
}
