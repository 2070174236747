import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteEditorComponent } from './screens/route-editor/route-editor.component';
import { LoginComponent } from './screens/login/login.component';
import { NotAuthorizedComponent } from './screens/not-authorized/not-authorized.component';
import { ShoppingListsComponent } from './screens/shopping-lists/shopping-lists.component';
import { WeekShoppingListComponent } from './screens/week-shopping-list/week-shopping-list.component';
import { ShoppingCartComponent } from './screens/shopping-cart/shopping-cart.component';
import { HomeComponent } from './screens/home/home.component';
import { ShoppingListComponent } from './screens/shopping-list/shopping-list.component';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
    },
    {
        path: 'home',
        component: HomeComponent,
    },
    {
        path: 'shopping/lists',
        component: ShoppingListsComponent,
    },
    {
        path: 'shopping-list',
        component: ShoppingListComponent,
    },
    /*{
        path: 'shopping-cart/:id',
        component: ShoppingCartComponent,
    },*/
    {
        path: 'shopping-cart',
        component: ShoppingCartComponent,
    },
    {
        path: 'week/shopping/list/:weekId',
        component: WeekShoppingListComponent,
    },
    {
        path: 'editor',
        component: RouteEditorComponent,
    },
    {
        path: 'not-authorized',
        component: NotAuthorizedComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
