<header *ngIf="userLogedIn$ | async">
    <app-info-header></app-info-header>
</header>

<main [ngClass]="{ 'is-logged-in': userLogedIn$ | async }">
    <router-outlet></router-outlet>
</main>

<footer *ngIf="userLogedIn$ | async">
    <app-navigation></app-navigation>
</footer>
<app-notification></app-notification>
<app-add-product-panel></app-add-product-panel>
