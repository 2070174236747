<nav class="main-nav">
    <ul>
        <li class="start"></li>
        <li>
            <a routerLink="home" routerLinkActive="active">
                <i class="fa-solid fa-home"></i>
            </a>
        </li>
        <!--<li>
            <a routerLink="shopping/lists" routerLinkActive="active">
                <i class="fa-solid fa-list"></i>
            </a>
        </li>-->
        <ng-container *ngIf="isShoppingCartSelected | async as hasSelectedShoppingCart">
            <li>
                <!--<a routerLink="week/shopping/list" routerLinkActive="active" [ngClass]="{ 'is-disabled': !hasSelectedShoppingCart }"><i class="fa-solid fa-apple-whole"></i></a>-->
                <a routerLink="shopping-list" routerLinkActive="active" [ngClass]="{ 'is-disabled': !hasSelectedShoppingCart }"><i class="fa-solid fa-apple-whole"></i></a>
            </li>
            <!--<li class="menu-toggle">
                <button (click)="toggleSubMenu()" class="btn-toggle-sub-menu" type="menu" aria-label="Untermen">
                    <i [@rotate90]="isSubMenuOpen ? 'rotateEnd90' : 'rotateStart90'" class="fa-solid fa-bars"></i>
                </button>
            </li>-->
            <li>
                <a routerLink="shopping-cart" routerLinkActive="active" [ngClass]="{ 'is-disabled': !hasSelectedShoppingCart }"><i class="fa-solid fa-cart-shopping"></i></a>
            </li>
        </ng-container>
        <li>
            <a routerLink="editor" routerLinkActive="active"><i class="fa-solid fa-screwdriver-wrench"></i></a>
        </li>
        <li class="end">
            <!--<button (click)="toggleSubMenu()" class="btn-toggle-sub-menu" type="menu" aria-label="Untermen">
                <i [@rotate90]="isSubMenuOpen ? 'rotateEnd90' : 'rotateStart90'" class="fa-solid fa-bars"></i>
            </button>-->
        </li>
    </ul>
    <ul [@toggleSubMenu]="isSubMenuOpen ? 'open' : 'close'" class="toggle-menu">
        <li>
            <a (click)="logout()"> <i class="fa-solid fa-arrow-right-from-bracket"></i> Logout </a>
        </li>
    </ul>
</nav>
