import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, Subject, filter, finalize, first, map, of, switchMap, tap } from 'rxjs';
import { Product } from '../models/product';
import { BackendCommunicationService } from './backend-communication.service';
import { DayShoppingList as DayShoppingListTest, ResponseWeekShoppingList, WeekShoppingData } from '../screens/week-shopping-list/week-shopping-list.component';
import { LocalDataService } from './local-data.service';
import { DayShoppingCart, ShoppingCart, WeekShoppingCart } from '../models/shopping-cart';
import { ShoppingList, DayShoppingList } from '../models/shopping-list';
import { SimpleProduct } from '../components/input-product-search/input-product-search.component';

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartService {
    private productCount = 0;
    private shoppingCartProductCnt = new BehaviorSubject<number>(0);
    private shoppingCartProductCnt$ = this.shoppingCartProductCnt.asObservable();

    private weekProductsArr?: WeekShoppingData;
    private weekProductsSubject = new Subject<WeekShoppingData>();
    private weekProducts$ = this.weekProductsSubject.asObservable();

    selectedShoppingListId = new BehaviorSubject<string>('');
    private selectShoppingList = new BehaviorSubject<boolean>(false);
    private isShoppingLisSelected$ = this.selectShoppingList.asObservable();

    weekShoppingList?: WeekShoppingData;

    weekShoppingListsData$ = new ReplaySubject<WeekShoppingData>();

    dayShoppingLists = new Subject<DayShoppingList[]>();

    private productListCnt = 0;
    private productListCnt$ = new ReplaySubject<number>(1);

    private productListCheckCnt = 0;
    private productListCheckCnt$ = new ReplaySubject<number>(1);

    private productsPrice = 0;
    private shoppingCartProductPrice$ = new ReplaySubject<number>(1);
    //private shoppingCartProductPrice$ = this.shoppingCartProductPrice.asObservable();

    private selectedShoppingList?: ShoppingList | null;
    private selectedShoppingList$ = new ReplaySubject<ShoppingList | null>(1);

    private shoppingListCollection: ShoppingList[] = [];
    private shoppingListCollection$ = new BehaviorSubject<ShoppingList[]>(this.shoppingListCollection);

    constructor(
        private backendCom: BackendCommunicationService,
        private localDataService: LocalDataService,
        private destroyRef: DestroyRef
    ) {
        this.initLocalData();
        //this.selectedShoppingList$.next(null);
    }

    private initLocalData() {
        const shoppingListCollection = this.localDataService.getShoppingListCollection();
        if (shoppingListCollection) {
            this.shoppingListCollection = shoppingListCollection;
            this.shoppingListCollection$.next(this.shoppingListCollection);
        }

        const selectedSHoppingListId = this.localDataService.getSelectedShoppingListId();
        if (selectedSHoppingListId) {
            this.setSelectedShoppingListById(selectedSHoppingListId);
        }
        /*
        if (selectedSHoppingListId) {
            this.getShoppingListById(selectedSHoppingListId)
                .pipe(first())
                .subscribe(selectedShoppingList => {
                    console.log(selectedShoppingList);
                    if (selectedShoppingList) {
                        console.log(selectedShoppingList);
                        this.selectedShoppingList = selectedShoppingList;
                        this.selectedShoppingList$.next(this.selectedShoppingList);
                    }
                });
        }*/
    }

    generateDayShoppingList(): void {}

    generateShoppingCart(): void {
        const id = Math.floor(Math.random() * 1000).toString();
        const genDateTime = new Date().getTime().toString();
        const newShoppingList: ShoppingList = { id: id, dateTime: genDateTime, productList: [], totalPrice: 0 };
        this.shoppingListCollection.unshift(newShoppingList);
        this.shoppingListCollection$.next(this.shoppingListCollection);
        this.setSelectedShoppingListById(id);
    }

    generateWeekShoppingCart(): void {
        const id = Math.floor(Math.random() * 1000).toString();
        const genDateTime = new Date().getTime().toString();
        const newShoppingCart: ShoppingList = { id: id, dateTime: genDateTime, weekProductList: [[], [], [], [], [], [], []], totalPrice: 0 };
        this.shoppingListCollection.unshift(newShoppingCart);
        this.shoppingListCollection$.next(this.shoppingListCollection);
        this.setSelectedShoppingListById(id);
    }

    setSelectedShoppingListById(id: string): void {
        const shoppingList = this.shoppingListCollection.find(shoppingList => shoppingList.id === id);
        if (shoppingList) {
            this.selectedShoppingList = shoppingList;
            this.selectedShoppingList$.next(this.selectedShoppingList);
            this.selectShoppingList.next(true);
            this.updateShoppingCartInfo();

            this.localDataService.setSelectedShoppingListId(id);
        } else {
            this.selectedShoppingList = null;
            this.selectedShoppingList$.next(null);
            this.selectShoppingList.next(false);
        }
    }

    deleteShoppingList(deleteShoppingList: ShoppingList): void {
        this.shoppingListCollection = this.shoppingListCollection.filter(shoppingList => shoppingList.id !== deleteShoppingList.id);
        this.shoppingListCollection$.next(this.shoppingListCollection);
        this.updateShoppingCartInfo();
    }

    getSelectedShoppingList(): Observable<ShoppingList | null> {
        return this.selectedShoppingList$.asObservable();
    }

    getSelectedShoppingListProducts(): Observable<Product[]> {
        let products: Product[] = [];

        if (this.selectedShoppingList?.productList) {
            products = this.selectedShoppingList.productList;
        } else if (this.selectedShoppingList?.weekProductList) {
            this.selectedShoppingList.weekProductList.forEach(dayProducts => {
                products = products.concat(dayProducts);
            });
        }

        return of(products);
    }

    getShoppingListById(id: string): Observable<ShoppingList | null> {
        const shoppingList = this.shoppingListCollection.find(shoppingList => shoppingList.id === id);

        if (shoppingList) {
            //this.selectedShoppingList = shoppingList;
            return this.selectedShoppingList$.asObservable();
        }

        this.selectedShoppingList$.next(null);
        return EMPTY;
    }

    toggleProductCheckInSelectedShoppingList(toggleProductCheck: Product): void {
        this.productListCheckCnt = 0;
        if (this.selectedShoppingList) {
            if (this.selectedShoppingList?.productList) {
                this.selectedShoppingList.productList.forEach(product => {
                    if (product.id === toggleProductCheck.id) {
                        product.check = toggleProductCheck.check;
                    }

                    this.productListCheckCnt += product.check ? 1 : 0;
                });
            } else {
                this.selectedShoppingList?.weekProductList?.forEach(dayProducts => {
                    dayProducts.forEach(product => {
                        if (product.id === toggleProductCheck.id) {
                            product.check = toggleProductCheck.check;
                            this.productListCheckCnt++;
                        }

                        this.productListCheckCnt += product.check ? 1 : 0;
                    });
                });
            }

            this.selectedShoppingList$.next(this.selectedShoppingList);
            this.updateShoppingCartInfo();
        }
    }

    getAllShoppingLists(): Observable<ShoppingList[]> {
        return this.shoppingListCollection$.asObservable();
    }

    getShoppingCartById(id: string): Observable<WeekShoppingCart | DayShoppingCart> {
        const shoppingCart = this.shoppingListCollection.find(shoppingCart => shoppingCart.id === id);
        if (shoppingCart) {
            return of(shoppingCart);
        }
        return EMPTY;
    }

    loadShoppingList(sellectedWeekFileId: string): Observable<WeekShoppingData> {
        this.selectedShoppingListId.next(sellectedWeekFileId);
        return this.getSelectedShoppingListId().pipe(
            takeUntilDestroyed(this.destroyRef),
            switchMap((weekId: string) => this.backendCom.loadUserWeekShoppingListById(weekId)),
            map((responseData: ResponseWeekShoppingList) => responseData.data),
            tap((weekShoppingData: WeekShoppingData) => {
                const cnt = weekShoppingData.week.map(day => day.shoppingList.length).reduce((acc, currCnt) => acc + currCnt);
                this.shoppingCartProductCnt.next(cnt);
                this.selectShoppingList.next(true);
                this.weekShoppingListsData$.next(weekShoppingData);
            })
        );
    }

    getWeekShoppingData(): Observable<WeekShoppingData> {
        return this.weekShoppingListsData$.asObservable();
    }

    getWeekShoppingListById(weekId: string): Observable<ResponseWeekShoppingList> {
        return this.backendCom.loadUserWeekShoppingListById(weekId).pipe(
            tap((responseData: ResponseWeekShoppingList) => {
                this.selectShoppingList.next(true);
                this.weekProductsArr = responseData.data;
                this.weekProductsSubject.next(this.weekProductsArr);

                //Count all products to update shopping cart product cnt
                if (responseData.data.week) {
                    const weekData: WeekShoppingData = responseData.data;
                    const daysInWeek = weekData.week;

                    daysInWeek.forEach((day: DayShoppingListTest) => {
                        this.productCount += day.shoppingList.length;
                        day.shoppingList.forEach((product: Product) => {
                            const productCount = product.cnt ? product.cnt : 0;
                            this.productsPrice += product.price * productCount;
                        });
                    });
                }
            }),
            finalize(() => {
                this.shoppingCartProductCnt.next(this.productCount);
                //this.shoppingCartProductPrice.next(this.productsPrice);
            })
        );
    }

    setShoppingCartSelected(seelcted: boolean): void {
        this.selectShoppingList.next(false);
    }

    setShoppingListId(selectedShoppingListId: string): void {
        this.selectedShoppingListId.next(selectedShoppingListId);
    }

    getSelectedShoppingListId(): Observable<string> {
        const savedShoppingListId = this.localDataService.getShoppingListKey();
        if (savedShoppingListId) {
            return of(savedShoppingListId);
        }
        return this.selectedShoppingListId.asObservable();
    }

    get isShoppingListSelected(): Observable<boolean> {
        return this.isShoppingLisSelected$;
    }

    getShoppingCartProductCnt(): Observable<number> {
        return this.productListCnt$;
    }

    getShoppingCartProductCheckCnt(): Observable<number> {
        return this.productListCheckCnt$;
    }

    getShoppingCartProductsPrice(): Observable<number> {
        return this.shoppingCartProductPrice$;
    }

    getWeekShoppingProducts(): Observable<WeekShoppingData> {
        return this.weekProducts$;
    }

    addProductByIdAndDayIndex(productId: number, dayIndex: number, productCnt: number): Observable<Product> {
        if (this.selectedShoppingList) {
            this.backendCom.addProductByIdAndDayIndex(productId, dayIndex, productCnt, this.selectedShoppingList.id).subscribe(product => {
                if (dayIndex === -1 && this.selectedShoppingList?.productList) {
                    const alraedyExistProductIndex = this.selectedShoppingList.productList.find(persistProduct => persistProduct.id === productId);

                    if (alraedyExistProductIndex && alraedyExistProductIndex.cnt) {
                        alraedyExistProductIndex.cnt += productCnt;
                    } else {
                        product.cnt = productCnt;
                        this.selectedShoppingList.productList.unshift(product);
                    }
                    this.selectedShoppingList$.next(this.selectedShoppingList);
                } else if (this.selectedShoppingList?.weekProductList) {
                    this.selectedShoppingList?.weekProductList[dayIndex].unshift(product);
                    this.selectedShoppingList$.next(this.selectedShoppingList);
                }

                this.updateShoppingCartInfo();
            });
        }

        return EMPTY;
    }
    private updateShoppingCartInfo(): void {
        this.productsPrice = 0;
        this.productListCnt = 0;
        this.productListCheckCnt = 0;

        if (this.selectedShoppingList) {
            if (this.selectedShoppingList.productList) {
                this.productsPrice = this.selectedShoppingList.productList.reduce((accPrice, currProduct) => {
                    this.productListCnt++;
                    const count = currProduct.cnt ?? 0;
                    this.productListCheckCnt += currProduct.check ? 1 : 0;
                    return (accPrice += currProduct.price * count);
                }, 0);
            } else {
                let alreadyExistProducts: number[] = [];
                this.selectedShoppingList?.weekProductList?.forEach(dayProductList => {
                    this.productsPrice += dayProductList.reduce((accPrice, currProduct) => {
                        if (!alreadyExistProducts.includes(currProduct.id)) {
                            this.productListCheckCnt += currProduct.check ? 1 : 0;
                            alreadyExistProducts.push(currProduct.id);
                        }
                        const count = currProduct.cnt ?? 0;
                        return (accPrice += currProduct.price * count);
                    }, 0);
                });

                this.productListCnt = alreadyExistProducts.length;
            }
            this.selectedShoppingList.totalPrice = this.productsPrice;
        }

        this.productListCnt$.next(this.productListCnt);
        this.productListCheckCnt$.next(this.productListCheckCnt);
        this.shoppingCartProductPrice$.next(this.productsPrice);

        this.localDataService.setShoppingListCollection(this.shoppingListCollection);
    }

    updateProduct(updateProduct: SimpleProduct, isUpdateProduct: boolean) {
        if (this.selectedShoppingList && this.selectedShoppingList.productList) {
            this.selectedShoppingList.productList
                .filter(product => product.id === updateProduct.id)
                .map(product => {
                    if (updateProduct.c && product.cnt) {
                        if (isUpdateProduct) {
                            product.cnt = updateProduct.c;
                        } else {
                            product.cnt += updateProduct.c;
                        }
                    }
                    return product;
                });
            this.selectedShoppingList$.next(this.selectedShoppingList);
            this.updateShoppingCartInfo();
        } else {
        }
    }

    updateProductCntByIdAndDayIndex(productId: string, dayIndex: number, productCnt: number): Observable<boolean> {
        return this.getSelectedShoppingListId().pipe(switchMap(currShoppingListId => this.backendCom.updateProductByIdAndDayIndex(productId, dayIndex, productCnt, currShoppingListId)));
        //this.backendCom.updateProductByIdAndDayIndex(productId, dayIndex, productCnt, currShoppingListId).pipe();
    }

    deleteProduct(productId: number, dayIndex: number): void {
        if (this.selectedShoppingList) {
            this.backendCom.deleteProduct(productId, dayIndex, this.selectedShoppingList.id).subscribe(isDeleted => {
                if (isDeleted) {
                    if (dayIndex === -1) {
                        const removeIndex = this.selectedShoppingList?.productList?.findIndex(persistProduct => persistProduct.id == productId);
                        if (removeIndex !== undefined && removeIndex > -1) {
                            this.selectedShoppingList?.productList?.splice(removeIndex, 1);
                        }
                    } else {
                        if (this.selectedShoppingList?.weekProductList) {
                            const removeIndex = this.selectedShoppingList?.weekProductList[dayIndex]?.findIndex(persistProduct => persistProduct.id == productId);
                            this.selectedShoppingList?.weekProductList[dayIndex].splice(removeIndex, 1);
                        }
                    }

                    if (this.selectedShoppingList) {
                        this.selectedShoppingList$.next(this.selectedShoppingList);
                        this.updateShoppingCartInfo();
                    }
                }
            });
        }

        /*
        return this.backendCom.deleteProduct(productId, dayIndex).pipe(
            tap((response: any) => {
                if (response?.data && response.data === true) {
                    this.productCount--;
                    this.shoppingCartProductCnt.next(this.productCount);
                }
            })
        );*/
    }

    getShoppingLists(): Observable<any> {
        return this.backendCom.loadUserShoppingLists();
    }

    generateShoppingLink(shoppingList: ShoppingList): Observable<string> {
        const currShoppingList = this.selectedShoppingList;
        if (shoppingList) {
            /*let productIds;
            if (shoppingList.productList) {
                productIds = shoppingList.productList.map(product => {
                    return {
                        id: product.id,
                        c: product.cnt,
                        ch: product.check ? product.check : false,
                    };
                });
            }*/

            /*const shoppingListLink = {
                id: currShoppingList.id,
                dt: currShoppingList.dateTime,
                pIds: productIds,
            };*/
            return this.backendCom.generateShoppingCopyLink(shoppingList);
        }
        return EMPTY;
    }

    loadShoppingListByLink(link: string): Observable<ShoppingList> {
        return this.backendCom.loadShoppingListByLink(link).pipe(
            tap(shoppingList => {
                this.selectedShoppingList = shoppingList;
                this.selectedShoppingList$.next(shoppingList);

                const existShoppingListIndex = this.shoppingListCollection.findIndex(persistShoppingList => persistShoppingList.id === shoppingList.id);

                if (existShoppingListIndex > -1) {
                    //                  this.shoppingListCollection[existShoppingListIndex] = shoppingList;
                } else {
                    //                    this.shoppingListCollection.unshift(shoppingList);
                }

                //  this.shoppingListCollection$.next(this.shoppingListCollection);
                this.selectShoppingList.next(true);
                this.updateShoppingCartInfo();
            })
        );
    }
}
