<div *ngIf="notification$ | async as notifiaction" class="notification-wrapper" [ngClass]="{ 'notification-show': !notifiaction.isClose, 'notification-hide': notifiaction.isClose }">
    <div *ngIf="notifiaction.duration" class="notification-loader-wrapper">
        <div class="notification-loader" [ngClass]="'load-' + notifiaction.duration + 's'"></div>
    </div>
    <div class="notification-content">
        <div class="notification-icon">
            <i class="fa-solid" [ngClass]="notifiaction.type"></i>
        </div>
        <div class="notification-descritpion">{{ notifiaction.msg }}</div>
        <div *ngIf="!notifiaction.duration" class="notification-action">
            <button (click)="executeAction(notifiaction)"><i class="fa" [ngClass]="notifiaction.action"></i></button>
        </div>
    </div>
</div>
