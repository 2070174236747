import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, map, of, tap } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { LoginUser } from '../models/login-user';
import { SimpleProduct } from '../components/input-product-search/input-product-search.component';
import { Product, ProductCategory, getProductCategoryById } from '../models/product';
import { ResponseWeekShoppingList } from '../screens/week-shopping-list/week-shopping-list.component';
import { Category, NewProduct } from '../components/add-product-panel/add-product-panel.component';
import { ShoppingList } from '../models/shopping-list';

/*
let MOCK_SIMPLE_PRODUCTS: SimpleProduct[] = [
    { id: '64e38643ad482', n: 'Chips', ca: ProductCategory.DROGERIE },
    { id: '64e3868663164', n: 'Kekse', ca: ProductCategory.SUSSIGKEIT },
    { id: '64e386f65817c', n: 'Ketchup', ca: ProductCategory.KONSERVE },
    { id: '64e386f65817a', n: 'Ketchup', ca: ProductCategory.KONSERVE },
];
*/
let MOCK_PRODUCTS: any[] = [
    { id: '64e38643ad482', name: 'Chips', category: ProductCategory.SUSSIGKEIT, price: '2.49' },
    { id: '64e38643ad483', name: 'Banane', category: ProductCategory.OBST, price: '2.49' },
    { id: '64e38643ad484', name: 'Apfel', category: ProductCategory.OBST, price: '2.49' },
    { id: '64e38643ad4841', name: 'Apfel rot', category: ProductCategory.OBST, price: '2.49' },
    { id: '64e38643ad4842', name: 'Apfel blau', category: ProductCategory.OBST, price: '2.49' },
    { id: '64e38643ad4843', name: 'Apfel gelb', category: ProductCategory.OBST, price: '2.49' },
    { id: '64e38643ad4844', name: 'Apfel orange', category: ProductCategory.OBST, price: '2.49' },
    { id: '64e38643ad4845', name: 'Apfel bunt', category: ProductCategory.OBST, price: '2.49' },
    { id: '64e38643ad4846', name: 'Möhren', category: ProductCategory.GEMUSE, price: '1.49' },
    { id: '64e38643ad4847', name: 'Apfel Mittel', category: ProductCategory.OBST, price: '2.49' },
    { id: '64e3868663164', name: 'Kekse', category: ProductCategory.SUSSIGKEIT, price: '1.39' },
    { id: '64e386f65817cb4', name: 'Ketchup', category: ProductCategory.KONSERVE, price: '1.89' },
    { id: '64e386f65817cb3', name: 'Bier Radeberger', category: ProductCategory.GETRANK, price: '0.89' },
    { id: '64e386f65817cb2', name: 'Bier Sternburg', category: ProductCategory.GETRANK, price: '1.89' },
    { id: '64e386f65817cb1', name: 'Bier Feldschlößchen', category: ProductCategory.GETRANK, price: '0.79' },
];

const MOCK_CATEGORIES: Category[] = [
    { id: ProductCategory.OBST, name: 'Obst' },
    { id: ProductCategory.GEMUSE, name: 'Gemüse' },
    { id: ProductCategory.KASE, name: 'Käse' },
    { id: ProductCategory.TK, name: 'Tiefkühl' },
    { id: ProductCategory.FISCH, name: 'Fisch' },
    { id: ProductCategory.WURST, name: 'Wurst' },
    { id: ProductCategory.KONSERVE, name: 'Konserve' },
];

@Injectable({
    providedIn: 'root',
})
export class BackendCommunicationService {
    constructor(private http: HttpClient) {}

    login(user: LoginUser): Observable<string> {
        return this.http.post<string>(env.apiUrl + '/user/login', {
            u: user.username,
            pw: user.password,
        });
    }

    loadProducts(): Observable<Product[]> {
        //return from([MOCK_PRODUCTS]);
        return this.http.get(env.apiUrl + '/products').pipe(map((response: any) => response.data));
    }

    loadCategories(): Observable<Category[]> {
        //return of(MOCK_CATEGORIES);
        return this.http.get<Category[]>(env.apiUrl + '/product/categories');
    }

    loadUserShoppingLists(): Observable<any> {
        return this.http.get<string>(env.apiUrl + '/user/week/shopping/lists');
    }

    loadUserWeekShoppingListById(weekId: string): Observable<ResponseWeekShoppingList> {
        return this.http.get<ResponseWeekShoppingList>(env.apiUrl + '/user/week/shopping/list/' + weekId);
    }

    addProductByIdAndDayIndex(productId: number, dayIndex: number, productCnt: number, shoppingListId: string): Observable<Product> {
        return this.http.get<any>(env.apiUrl + '/product/id/' + productId);

        const foundProduct = MOCK_PRODUCTS.find(product => product.id === productId);
        const prod = { ...foundProduct, cnt: productCnt } as Product;
        return of(prod);

        const addProductbject = {
            productId: productId,
            productCnt: productCnt,
            dayIndex: dayIndex,
            shoppingListId: shoppingListId,
        };

        return this.http.post<any>(env.apiUrl + '/product/to/shopping/list', addProductbject).pipe(map((result: any) => result.data));
    }

    updateProductByIdAndDayIndex(productId: string, dayIndex: number, productCnt: number, shoppingListId: string): Observable<boolean> {
        const addProductbject = {
            productId: productId,
            productCnt: productCnt,
            dayIndex: dayIndex,
            shoppingListId: shoppingListId,
        };
        return this.http.put<any>(env.apiUrl + '/product/to/shopping/list', addProductbject).pipe(map((result: any) => result.data));
    }

    deleteProduct(productId: number, dayIndex: number, shoppingListId: string): Observable<boolean> {
        return of(true);
        return this.http.delete<boolean>(env.apiUrl + '/product/from/shopping/list/' + productId + '/' + dayIndex + '/' + shoppingListId);
    }

    loadFilteredProduct(filterInput: string): Observable<SimpleProduct[]> {
        filterInput = encodeURIComponent(filterInput);
        return this.http.get<SimpleProduct[]>(env.apiUrl + '/product/filter/simple/' + filterInput);

        /*MOCK_SIMPLE_PRODUCTS = MOCK_PRODUCTS.map(product => {
            return {
                id: product['id'],
                n: product['name'],
                ca: product['category'],
            } as SimpleProduct;
        });
        filterInput = filterInput.toLowerCase();
        const filteredProducts = MOCK_SIMPLE_PRODUCTS.filter(product => product.n.toLowerCase().indexOf(filterInput) >= 0);
        return of(filteredProducts);
        return this.http.get<SimpleProduct[]>(env.apiUrl + '/products/filter/' + filterInput);
    */
    }

    loadProductById(productId: number): Observable<Product> {
        return this.http.get<Product>(env.apiUrl + '/product/' + productId).pipe(map((response: any) => response.data));
    }

    generateNewProduct(newProduct: NewProduct): Observable<Product> {
        const product = {
            name: newProduct.n,
            category: parseInt(newProduct['ca']),
            price: newProduct.p,
        } as Product;

        return this.http.post<Product>(env.apiUrl + '/product/new', product);

        console.log(product);

        MOCK_PRODUCTS.unshift(product);

        return of(product);
    }

    generateShoppingCopyLink(shoppingListCopy: ShoppingList): Observable<string> {
        return this.http.post<string>(env.apiUrl + '/product/generate/copy/link', shoppingListCopy);
    }

    loadShoppingListByLink(link: string): Observable<ShoppingList> {
        return this.http.get<ShoppingList>(env.apiUrl + '/product/copy/link/' + link);
    }
}
