<div class="content">
    <header>
        <h2><i class="fa-solid fa-home"></i> Food Route</h2>
        <p>Verwalte und Teile deine Einkäufe.</p>
    </header>
    <section>
        <div class="buttons-generate">
            <button class="btn-primary" (click)="addNewShoppingCart()">
                <h3><i class="fa-solid fa-basket-shopping" aria-hidden="true"></i> Tag</h3>
                <small>Einkaufszettel hinzufügen</small>
                <div class="color-point product-list-color" [ngClass]="{ 'generation-animation': isGenerateNewShoppingList }" aria-hidden="true"></div>
            </button>
            <button class="btn-primary" (click)="addNewWeekShoppingCart()">
                <h3><i class="fa-solid fa-calendar-days" aria-hidden="true"></i> Woche</h3>
                <small>Wocheneinkauf hinzufügen</small>
                <div class="color-point week-product-list-color" [ngClass]="{ 'generation-animation': isGenerateNewWeekShoppingList }" aria-hidden="true"></div>
            </button>
        </div>
    </section>

    <section>
        <h3>Ausgewälte Einkaufsliste</h3>
        <ng-container *ngIf="selectedShoppingList$ | async as selectedShoppingList">
            <div class="tile current-shopping-tile" [ngClass]="{ 'product-list': selectedShoppingList.productList, 'week-product-list': selectedShoppingList.weekProductList }">
                <div class="tile-content">
                    <div class="shopping-value">
                        <small>Produkt</small>
                        <div class="big">{{ selectedShoppingListProductCnt }}</div>
                    </div>
                    <div class="shopping-value">
                        <small>Preis</small>
                        <div class="big">{{ selectedShoppingList.totalPrice | currency: 'EUR' }}</div>
                    </div>
                    <!--
                    <h2>Produkt Anzhal {{ selectedShoppingList.totalPrice }}</h3>
                    <h3>Produkt Anzhal {{ selectedShoppingList.totalPrice }}</h3>
                    <h3>Preis{{ selectedShoppingList.totalPrice }}</h3>
                    -->
                </div>
            </div>
        </ng-container>
    </section>

    <section>
        <h3>Überblick Einkaufslisten</h3>
        <ul *ngIf="allShoppingCartList$ | async as shoppingLists">
            <li *ngFor="let shoppingList of shoppingLists; let i = index" class="tile" [ngClass]="{ 'product-list': shoppingList.productList, 'week-product-list': shoppingList.weekProductList }">
                <div class="tile-icon">
                    <i *ngIf="shoppingList.productList" class="fa-solid fa-basket-shopping" aria-hidden="true"></i>
                    <i *ngIf="shoppingList.weekProductList" class="fa-solid fa-calendar-days" aria-hidden="true"></i>
                </div>
                <div (click)="goToShoppingCart(shoppingList)" class="tile-content">
                    <div *ngIf="shoppingList.productList">Einkaufszettel</div>
                    <div *ngIf="!shoppingList.productList">Wocheneinkauf</div>
                    <small>{{ shoppingList.dateTime | date: 'dd.MMM yyyy' }} | {{ shoppingList.totalPrice | currency: 'EUR' }}</small>
                </div>
                <div class="tile-actions">
                    <button (click)="generateShoppingListLink(shoppingList, i)" class="btn btn-primary" aria-label="Wocheinkauf löschen">
                        <i class="fa-solid fa-link" aria-hidden="true"></i>
                    </button>
                    <button *ngIf="shoppingList.productList" (click)="deleteShoppingList(shoppingList)" class="btn btn-primary" aria-label="Einkaufzettel löschen">
                        <i class="fa-solid fa-times" aria-hidden="true"></i>
                    </button>
                    <button *ngIf="shoppingList.weekProductList" (click)="deleteShoppingList(shoppingList)" class="btn btn-primary" aria-label="Wocheinkauf löschen">
                        <i class="fa-solid fa-times" aria-hidden="true"></i>
                    </button>
                </div>
            </li>
        </ul>
    </section>
</div>
