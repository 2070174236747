import { Injectable } from '@angular/core';
import { Observable, Subject, takeUntil, timer } from 'rxjs';
import { Notification } from '../models/notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private incomingNotification$ = new Subject<Notification | null>();
    private destroyLastNotify$ = new Subject<void>();

    onIncomingNotifications(): Observable<Notification | null> {
        return this.incomingNotification$.asObservable();
    }

    notify(notification: Notification): void {
        this.incomingNotification$.next(null);
        this.incomingNotification$.next(notification);
    }

    remove() {
        this.destroyLastNotify$.next();
        this.incomingNotification$.next(null);
    }
}
