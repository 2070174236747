export enum NotificationTyp {
    INFO = 'fa-circle-info',
    WARNING = 'fa-triangle-exclamation',
    ERROR = 'fa-circle-exclamation',
}

export enum NotificationDuration {
    SHORT = 3,
    MIDDLE = 5,
    LONG = 8,
}

export enum NotificationAction {
    CLOSE = 'fa-times',
    COPY = 'fa-copy',
}

export interface Notification {
    type: NotificationTyp;
    msg: string;
    copyMsg?: string;
    duration?: NotificationDuration;
    action?: NotificationAction;
    isClose?: boolean;
}
