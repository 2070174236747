import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';

@Component({
    selector: 'app-info-header',
    templateUrl: './info-header.component.html',
    styleUrls: ['./info-header.component.scss'],
})
export class InfoHeaderComponent {
    shoppingCartProductCnt$ = new Observable<number>();
    shoppingCartProductCheckCnt$ = new Observable<number>();
    shoppingCartProductsPrice$ = new Observable<number>();

    constructor(private shoppingCarService: ShoppingCartService) {
        this.shoppingCartProductCnt$ = shoppingCarService.getShoppingCartProductCnt();
        this.shoppingCartProductCheckCnt$ = shoppingCarService.getShoppingCartProductCheckCnt();
        this.shoppingCartProductsPrice$ = shoppingCarService.getShoppingCartProductsPrice();
    }
}
