import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Level, Shelf } from 'src/app/models/shelf';
import { SimpleProduct } from '../input-product-search/input-product-search.component';
import { ProductsService } from 'src/app/services/products.service';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { Category } from '../add-product-panel/add-product-panel.component';
import { ProductCategory } from 'src/app/models/product';

@Component({
    selector: 'app-shelf',
    templateUrl: './shelf.component.html',
    styleUrls: ['./shelf.component.scss'],
})
export class ShelfComponent implements OnDestroy {
    @Input() shelf?: Shelf[];
    @Output() emitChangeShelf = new EventEmitter<Shelf[]>();

    productCategory = ProductCategory;
    selectedLevel?: number;

    destroy$ = new Subject<void>();
    categories$ = new Observable<Category[]>();
    constructor(private productsService: ProductsService) {
        productsService
            .onNewGeneratedProduct()
            .pipe(
                takeUntil(this.destroy$),
                map(newGeneratedProduct => {
                    return {
                        id: newGeneratedProduct.id,
                        n: newGeneratedProduct.n,
                        ca: ProductCategory.EMPTY,
                    };
                })
            )
            .subscribe(newSimpleProduct => {
                if (this.shelf) {
                    this.shelf.forEach(shelf => {
                        if (this.selectedLevel !== undefined) {
                            shelf.level[this.selectedLevel].product = newSimpleProduct;
                        }
                    });
                }
            });

        this.categories$ = this.productsService.getCategories();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    addLevel(): void {
        if (this.shelf) {
            const level = {} as Level;
            this.shelf.forEach(shelf => {
                if (shelf.level) {
                    shelf.level.push(level);
                } else {
                    shelf.level = [level];
                }
            });
        }
        this.emitChangeShelf.emit(this.shelf);
    }

    removeLevel(levelIndex: number): void {
        if (this.shelf) {
            this.shelf.forEach(shelf => {
                shelf.level.splice(levelIndex, 1);
            });
            this.selectedLevel = undefined;
        }
    }

    setProduct(product: SimpleProduct): void {
        if (this.shelf) {
            this.shelf.forEach(shelf => {
                if (this.selectedLevel !== undefined) {
                    shelf.level[this.selectedLevel].product = product;
                }
            });
        }
    }

    setSelectedLevel(index: number): void {
        if (index !== this.selectedLevel) {
            this.selectedLevel = index;
        } else {
            this.selectedLevel = undefined;
        }
    }

    setCategory(categroyId: ProductCategory): void {
        if (this.shelf) {
            this.shelf.forEach(shelf => {
                shelf.category = categroyId; //categroyId !== '' ? categroyId : undefined;
            });
            this.emitChangeShelf.emit(this.shelf);
        }
    }
}
