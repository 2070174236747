import { Injectable } from '@angular/core';
import { Product } from '../models/product';
import { BehaviorSubject, Observable, ReplaySubject, Subject, filter, first, map, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BackendCommunicationService } from './backend-communication.service';
import { SimpleProduct } from '../components/input-product-search/input-product-search.component';
import { Category, NewProduct } from '../components/add-product-panel/add-product-panel.component';
@Injectable({
    providedIn: 'root',
})
export class ProductsService {
    productList: Product[] = [];
    productListHandler = new BehaviorSubject<Product[]>(this.productList);
    productList$: Observable<Product[]> = this.productListHandler.asObservable();
    products$ = new BehaviorSubject<Product[]>([]);
    showNewProductsPanel = new Subject<SimpleProduct | null>();
    generatedProduct = new Subject<SimpleProduct>();
    categories = new ReplaySubject<any>();

    cachedFilterProducts: SimpleProduct[] = [];

    constructor(private backendCom: BackendCommunicationService) {
        backendCom.loadCategories().subscribe((response: any) => {
            this.categories.next(response);
        });
    }

    initProducts() {
        this.backendCom.loadProducts().subscribe(
            (products: Product[]) => {
                this.products$.next(products);
            },
            (error: unknown) => console.log(error)
        );
    }

    getProductList(): Observable<Product[]> {
        return this.backendCom.loadProducts();
    }

    getProducts(): Observable<Product[]> {
        return this.products$.asObservable();
    }

    getCategories(): Observable<Category[]> {
        return this.categories.asObservable();
    }

    filterProducts(filterInput: string): Observable<SimpleProduct[]> {
        if (filterInput && filterInput.length > 2) {
            const cahcedSimpleProducts = this.isNotInCachedProduct(filterInput);
            //console.log
            //if (cahcedSimpleProducts.length === 0) {
            return this.backendCom.loadFilteredProduct(filterInput).pipe(tap(products => this.cacheProducts(products)));
            //} else {
            //    console.log('LOAD FROM CACHE');
            //    return of(cahcedSimpleProducts);
            //}
        }
        return of([]).pipe(first());
    }

    getProductById(productId: number): Observable<Product> {
        return this.backendCom.loadProductById(productId);
    }

    generateProduct(newProduct: NewProduct): Observable<Product> {
        return this.backendCom.generateNewProduct(newProduct).pipe(
            filter(product => product !== null),
            tap((product: Product) => {
                const simpleProduct = {
                    id: product.id,
                    ca: product.category,
                    c: newProduct.c,
                    n: product.name,
                } as SimpleProduct;

                this.generatedProduct.next(simpleProduct);
            })
        );
    }

    isShowNewProductPanel(): Observable<SimpleProduct | null> {
        return this.showNewProductsPanel.asObservable();
    }

    showNewProductPanel(presetProductName: SimpleProduct): void {
        return this.showNewProductsPanel.next(presetProductName);
    }

    hideNewProductPanel(): void {
        return this.showNewProductsPanel.next(null);
    }

    onNewGeneratedProduct(): Observable<SimpleProduct> {
        return this.generatedProduct.asObservable();
    }

    private cacheProducts(products: SimpleProduct[]) {
        this.cachedFilterProducts = this.cachedFilterProducts.concat(products);
    }

    private isNotInCachedProduct(filterInput: string): SimpleProduct[] {
        return this.cachedFilterProducts.filter(filterProduct => filterProduct.n.toLowerCase().indexOf(filterInput) >= 0);
        //return findProduct.length === 0;
    }
}
