export enum ProductCategory {
    EMPTY = -1,
    OBST = 0,
    GEMUSE = 1,
    GEBACK = 2,
    CEREALIEN = 3,
    JOGUHRT = 4,
    KASE = 5,
    TK = 6,
    FISCH = 7,
    EIS = 8,
    WURST = 9,
    FLEISCH = 10,
    KAFFEE = 11,
    TEE = 12,
    KONSERVE = 13,
    DROGERIE = 14,
    SUSSIGKEIT = 15,
    GETRANK = 16,
    MILCHPRODUCT = 17,
    PASTA = 18,
    BACKWARE = 19,
    GEWURZ = 20,
    SONSITGES = 100,
}

export const CategoryColor: ProductType = {
    EMPTY: '#ffffff',
    OBST: '#ecb40d',
    GEMUSE: '#8ab70e',
    GEBACK: '#b9751d',
    CEREALIEN: '#43bfa2',
    MILCHPRODUKT: '#fdf3ca',
    GETREIDE: '#7b6354',
    WURST: '#8f4141',
    FLEISCH: '#c81a1a',
    KASE: '#978b74',
    EIS: '#cf9dd7',
    FISCH: '#15666f',
    KAFFEE: '#7b6055',
    TK: '#007fee',
    PASTA: '#e1854d',
    GETRANK: '#82196d',
    KONSERVE: '#ffaa09',
    SUSSIGKEIT: '#ff666f',
    DROGERIE: '#97cbf9',
    BACKWARE: '#b3aa62',
    GEWURZ: '#343739',
    SONSITGES: '#c24103',
};

export const CategoryName: ProductType = {
    EMPTY: '',
    OBST: 'Obst',
    GEMUSE: 'Gemüse',
    GEBACK: 'Gebäck',
    CEREALIEN: 'Cerialien',
    MILCHPRODUKT: 'Milchprodukt',
    GETREIDE: 'Getreide',
    WURST: 'Wurst',
    FLEISCH: 'Fleisch',
    KASE: 'Käse',
    EIS: 'Eis',
    FISCH: 'Fisch',
    KAFFEE: 'Kaffee',
    TK: 'Tiefkühl',
    PASTA: 'Pasta',
    GETRANK: 'Getränk',
    KONSERVE: 'Konserve',
    SUSSIGKEIT: 'Süssigkeit',
    DROGERIE: 'Drogerie',
    BACKWARE: 'Backware',
    GEWURZ: 'Gewürz',
    SONSITGES: 'Sonstiges',
};

export const CategoryIcon: ProductType = {
    EMPTY: '',
    OBST: 'fa-apple-whole',
    GEMUSE: 'fa-carrot',
    GEBACK: 'fa-bread-slice',
    MILCHPRODUCT: 'fa-cow',
    GETREIDE: 'fa-wheat-awn',
    WURST: 'fa-bacon',
    KASE: 'fa-cheese',
    TK: 'fa-pizza-slice',
    FISCH: 'fa-fish',
    PASTA: 'fa-bowl-food',
    GETRANK: 'fa-bottle-droplet',
    KONSERVE: 'fa-hockey-puck',
    SUSSIGKEIT: 'fa-cookie',
    DROGERIE: 'fa-pump-soap',
    SONSITGES: 'fa-question',
};

export interface Product {
    id: number;
    name: string;
    cnt?: number;
    category: number;
    price: number;
    isEdit?: boolean;
    check: boolean;
}

export interface CategoryProducts {
    products: Product[];
}

export interface ProductType {
    [key: string]: string;
}

export function getProductCategoryById(categoryString: string): ProductCategory {
    switch (categoryString) {
        case 'OBST':
            return ProductCategory.OBST;
            break;
        case 'GEMUSE':
            return ProductCategory.GEMUSE;
            break;
        case 'MILCHPRODUCT':
            return ProductCategory.MILCHPRODUCT;
            break;
        case 'CEREALIEN':
            return ProductCategory.CEREALIEN;
            break;
        case 'WURST':
            return ProductCategory.WURST;
            break;
        case 'KASE':
            return ProductCategory.KASE;
            break;
        case 'TK':
            return ProductCategory.TK;
            break;
        case 'FISCH':
            return ProductCategory.FISCH;
            break;
        case 'PASTA':
            return ProductCategory.PASTA;
            break;
        case 'GETRANK':
            return ProductCategory.GETRANK;
            break;
        case 'KONSERVE':
            return ProductCategory.KONSERVE;
            break;
        case 'SUSSIGKEIT':
            return ProductCategory.SUSSIGKEIT;
            break;
        case 'DROGERIE':
            return ProductCategory.DROGERIE;
            break;
        case 'SONSITGES':
            return ProductCategory.SONSITGES;
            break;
        default:
            return ProductCategory.EMPTY;
            break;
    }
}

/*
export interface CategoryIcon {
    [ProductCategory.OBST]:string,
    [ProductCategory.GEMUSE]:string,
    [ProductCategory.MILCHPRODUCT]:string,
    [ProductCategory.GETREIDE]:string,
    [ProductCategory.WURST]:string,
    [ProductCategory.KASE]:string,
    [ProductCategory.TK]:string,
    [ProductCategory.FISCH]:string,
    [ProductCategory.PASTA]:string,
    [ProductCategory.GETRANK]:string,
    [ProductCategory.KONSERVE]:string,
    [ProductCategory.SUSSIGKEIT]:string,
    [ProductCategory.DROGERIE]:string,
    [ProductCategory.SONSITGES]:string
  }*/
