<nav>
    <ul>
        <li class="item-infos">
            <i class="fa fa-cart-shopping"></i>
            <ng-container *ngIf="shoppingCartProductCnt$ | async as cnt; else templNullValue">{{ cnt }}</ng-container>
            /
            <ng-container *ngIf="shoppingCartProductCheckCnt$ | async as checkCnt; else templNullValue">{{ checkCnt }}</ng-container>
            <i class="fa fa-check"></i>

            <ng-template #templNullValue>0</ng-template>
        </li>
        <li class="item-logo">
            <div class="logo-section">
                <div class="logo">
                    <app-logo></app-logo>
                </div>
            </div>
        </li>
        <li class="item-infos">
            <ng-container *ngIf="shoppingCartProductsPrice$ | async as price; else templNullPriceValue"> {{ price | currency: ' ' : 'symbol' }} <i class="fa fa-solid fa-euro-sign"></i> </ng-container>
            <ng-template #templNullPriceValue>{{ 0 | currency: ' ' : 'symbol' }} <i class="fa fa-solid fa-euro-sign"></i></ng-template>
        </li>
    </ul>
</nav>
