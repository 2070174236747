<header *ngIf="dayIndex != -1" (click)="toggleShoppingList()" class="header-day-shopping">
    <h3 class="title-day">{{ dayName }} ({{ dayShoppingList.length }})</h3>
    <div class="title-toggle-arrow">
        <i [@rotate]="isExpandProductsContainer ? 'rotateEnd' : 'rotateStart'" class="fa-solid fa-circle-chevron-down"></i>
    </div>
</header>
<div [@expandCollapse]="isExpandProductsContainer ? 'expanded' : 'collapsed'" class="products-collection-container">
    <app-input-product-search [dayIndex]="dayIndex" [updateProduct]="selectedUpdateProduct" (blurUpdate)="onBlurProductUpdate()" (addProduct)="onAddProduct($event)"></app-input-product-search>

    <ng-container *ngIf="productList$ | async as productList">
        <ul>
            <li *ngFor="let product of productList; let i = index" class="tile-wrapper">
                <div
                    class="tile"
                    [ngClass]="{
                        'in-update-mode': currUpdateIndex !== null && i !== currUpdateIndex,
                        'is-delete': currDeleteIndex !== null && i === currDeleteIndex
                    }">
                    <div
                        class="tile-icon"
                        [ngStyle]="{
                            backgroundColor: product.category | productCategoryColor
                        }">
                        <i class="fa" [ngClass]="product.category | productCategoryIcon"></i>
                    </div>
                    <div (click)="toggleUpdateProduct(product, i)" class="tile-content">
                        <span
                            >{{ product.cnt }} <i class="fa fa-times"></i>
                            {{ product.name }}
                        </span>
                        <small
                            >{{ product.category | productCategoryName }} - {{ product.price | currency: 'EUR' }} |
                            <ng-container *ngIf="product.cnt">{{ product.price * product.cnt | currency: 'EUR' }}</ng-container></small
                        >
                    </div>

                    <div class="tile-actions">
                        <button (click)="deleteProduct(product, i)">
                            <i class="fa fa-trash-can"></i>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <!--
        <div
            *ngFor="let product of productList; let i = index"
            [ngClass]="{
                'in-update-mode': currUpdateIndex !== null && i !== currUpdateIndex
            }"
            class="product-tile">
            <div
                class="product-category"
                [ngStyle]="{
                    backgroundColor: product.category | productCategoryColor
                }">
                <i class="fa" [ngClass]="product.category | productCategoryIcon"></i>
            </div>

            <div (click)="toggleUpdateProduct(product, i)" class="product-description">
                <div class="product-top">
                    <h3>
                        {{ product.cnt }} <i class="fa fa-times"></i>
                        {{ product.name }}
                    </h3>
                </div>
                <div class="product-bototm">
                    <small
                        >{{ product.category | productCategoryName }} - {{ product.price | currency: 'EUR' }} |
                        <ng-container *ngIf="product.cnt">{{ product.price * product.cnt | currency: 'EUR' }}</ng-container></small
                    >
                </div>
            </div>

            <div class="product-actions">
                <button (click)="deleteProduct(product, i)">
                    <i class="fa fa-trash-can"></i>
                </button>
            </div>
        </div>-->
    </ng-container>
</div>
