import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, tap } from 'rxjs';
import { LoginUser } from '../models/login-user';
import { BackendCommunicationService } from './backend-communication.service';
import { LocalDataService } from './local-data.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    userLogin = new BehaviorSubject(false);
    //private isUserLoggedIn = this.userLogin.asObservable();

    constructor(
        private backendCom: BackendCommunicationService,
        private localData: LocalDataService,
        private router: Router
    ) {}

    get isUserLoggedIn(): Observable<boolean> {
        return this.userLogin.asObservable();
    }

    tryAutoLogin(): boolean {
        const userKey = this.localData.getUserKey();
        if (userKey) {
            this.handleLogin(userKey);
            return true;
        }
        this.handleLogin(null);
        return false;
    }

    login(user: LoginUser): Observable<boolean> {
        this.handleLogin('TEST');
        return of(true);
        return this.backendCom.login(user).pipe(
            tap((result: any) => this.handleLogin(result.data)),
            map((result: any) => result.data != null)
        );
    }

    logout(): void {
        this.handleLogin(null);
        this.router.navigate(['']);
    }

    private handleLogin(userKey: any): void {
        if (userKey && userKey !== null) {
            this.userLogin.next(true);
            this.localData.setUserKey(userKey);
        } else {
            this.userLogin.next(false);
            this.localData.removeAllKeys();
        }
    }
}
