import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { LoginUser } from 'src/app/models/login-user';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;

    constructor(
        private userService: UserService,
        private router: Router
    ) {
        this.loginForm = new FormGroup({
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
        });
    }

    ngOnInit(): void {
        //Check auto login
        if (this.userService.tryAutoLogin()) {
            this.router.navigate(['home']);
        }

        //this.router.navigate(['shopping/lists']);
    }

    onSubmit(): void {
        if (this.loginForm.valid) {
            const user: LoginUser = this.loginForm.getRawValue();
            this.userService.login(user).subscribe(loginSuccess => {
                if (loginSuccess) {
                    this.router.navigate(['home']);
                } else {
                    alert('Login fehlerhaft');
                    this.loginForm.reset();
                }
            });
        } else {
            alert('Bitte fülle das Formular aus');
        }
    }
}
