import { Product } from './product';
import { Shelf } from './shelf';

export interface DiscounterRoom {
    width: number;
    height: number;
    enviromentTiles: Map<string, Tile>;
}

export interface Tile {
    index: number;
    x: number;
    y: number;
    id?: string;
    type: InteriorType;
    shelf?: Shelf;
    distance?: number;
    isMarkRoute?: boolean;
    routeDirection?: string;
    isNodeCorner?: boolean;
    isNodeChecked?: boolean;
    nodeNeighbors?: Tile[];
}

export enum InteriorType {
    WALL = 'WALL',
    WAY = 'WAY',
    SHELF = 'SHELF',
    BORDER = 'BORDER',
    CASH_TERMINAL = 'CASH_TERMINAL',
    ENTRANCE = 'ENTRANCE',
}

export enum ToolType {
    NODE_CORNER_ADD = 'NODE_CORNER_ADD',
    NODE_CORNER_DELETE = 'NODE_CORNER_DELETE',
}

export type TileInfo = Product;
