import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, map, takeUntil, tap } from 'rxjs';
import { LocalDataService } from 'src/app/services/local-data.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';

export interface WeekShoppingListFile {
    w: string;
    from: string;
    to: string;
}

export interface ResponseWeekShoppingListFile {
    data: WeekShoppingListFile[];
}

@Component({
    selector: 'app-shopping-lists',
    templateUrl: './shopping-lists.component.html',
    styleUrls: ['./shopping-lists.component.scss'],
})
export class ShoppingListsComponent implements OnInit, OnDestroy {
    weekShoppingListsFiles$: Observable<WeekShoppingListFile[]> = new Observable();

    destroy$ = new Subject<void>();
    constructor(
        private shoppingCartService: ShoppingCartService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.weekShoppingListsFiles$ = this.shoppingCartService.getShoppingLists().pipe(map((result: ResponseWeekShoppingListFile) => result.data));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    goToWeekShoppingList(weekFile: WeekShoppingListFile) {
        const weekFileId = weekFile.w + '_' + weekFile.from + '_' + weekFile.to;
        this.router.navigate(['week/shopping/list/' + weekFileId]);
    }
}
