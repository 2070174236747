<div class="content">
    <ng-container *ngIf="shoppingList$ | async as shoppingList; else template">
        <ng-container *ngIf="shoppingList.productList">
            <header>
                <h2 class="title"><i class="fa-solid fa-apple-whole"></i> Enikaufszettel</h2>
                <p>Füge Deine Produkte hinzu.</p>
                <!--<button (click)="generateShoppingLink()">Generate shopping Link</button>-->
            </header>
            <app-day-shopping-list [dayShoppingList]="shoppingList.productList" [isExpandProductsContainer]="true"></app-day-shopping-list>
        </ng-container>
        <ng-container *ngIf="shoppingList.weekProductList">
            <header>
                <h2 class="title"><i class="fa-solid fa-apple-whole"></i> Wocheneinkauf</h2>
                <p>Füge Deine Produkte hinzu.</p>
            </header>
            <app-day-shopping-list (toggleDay)="toggleDay($event)" [dayShoppingList]="shoppingList.weekProductList[0]" [dayIndex]="0" [currOpenDayIndex]="currOpenDayIndex"></app-day-shopping-list>

            <app-day-shopping-list (toggleDay)="toggleDay($event)" [dayShoppingList]="shoppingList.weekProductList[1]" [dayIndex]="1" [currOpenDayIndex]="currOpenDayIndex"></app-day-shopping-list>

            <app-day-shopping-list (toggleDay)="toggleDay($event)" [dayShoppingList]="shoppingList.weekProductList[2]" [dayIndex]="2" [currOpenDayIndex]="currOpenDayIndex"></app-day-shopping-list>

            <app-day-shopping-list (toggleDay)="toggleDay($event)" [dayShoppingList]="shoppingList.weekProductList[3]" [dayIndex]="3" [currOpenDayIndex]="currOpenDayIndex"></app-day-shopping-list>

            <app-day-shopping-list (toggleDay)="toggleDay($event)" [dayShoppingList]="shoppingList.weekProductList[4]" [dayIndex]="4" [currOpenDayIndex]="currOpenDayIndex"></app-day-shopping-list>

            <app-day-shopping-list (toggleDay)="toggleDay($event)" [dayShoppingList]="shoppingList.weekProductList[5]" [dayIndex]="5" [currOpenDayIndex]="currOpenDayIndex"></app-day-shopping-list>

            <app-day-shopping-list (toggleDay)="toggleDay($event)" [dayShoppingList]="shoppingList.weekProductList[6]" [dayIndex]="6" [currOpenDayIndex]="currOpenDayIndex"></app-day-shopping-list>
        </ng-container>
    </ng-container>

    <ng-template #template>
        <h2>Keine Einkaufsliste gefunden</h2>
        <a routerLink="/home">Zur Startseite</a>
    </ng-template>
</div>
