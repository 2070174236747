<div class="shelf-details">
    <ng-container *ngIf="shelf">
        <hr />
        <ul *ngIf="shelf.length > 0">
            <li>
                Kategorie: <b>{{ shelf[0].category }}</b>
            </li>
            <li>ID: {{ shelf[0].id }}</li>
            <li>
                Position: x: {{ shelf[0].position.x }}, y:
                {{ shelf[0].position.y }}
            </li>
        </ul>
        <hr />

        <div class="shelf-category">
            <label>Regal Kategorie</label>

            <select>
                <option (click)="setCategory(productCategory.EMPTY)" value="-">- Wählen -</option>
                <ng-container *ngIf="categories$ | async as categories">
                    <option (click)="setCategory(categorie.id)" *ngFor="let categorie of categories" [value]="categorie.id">
                        {{ categorie.name }}
                    </option>
                </ng-container>
            </select>
        </div>

        <div class="shelf-levels">
            <div
                (click)="setSelectedLevel(i)"
                class="level"
                [ngClass]="{
                    'level-selected': selectedLevel !== undefined && selectedLevel === i
                }"
                *ngFor="let level of shelf[0].level; let i = index">
                <div class="product">
                    <ng-container *ngIf="level.product; else emptyTemp">
                        {{ level.product.n }}
                    </ng-container>

                    <ng-template #emptyTemp> - </ng-template>
                </div>
                <button (click)="removeLevel(i)">
                    <i class="fa-regular fa-square-minus"></i>
                </button>
            </div>
        </div>
        <button (click)="addLevel()"><i class="fa fa-plus"></i> Regal Ebene Hinzufügen</button>

        <ng-container *ngIf="selectedLevel !== undefined">
            <app-input-product-search (addProduct)="setProduct($event)"></app-input-product-search>
        </ng-container>
    </ng-container>
</div>
