import { Pipe, PipeTransform } from '@angular/core';
import { CategoryColor, ProductCategory } from '../models/product';

@Pipe({
    name: 'productCategoryColor',
})
export class ProductCategoryColorPipe implements PipeTransform {
    transform(category: ProductCategory | undefined): string {
        return category !== undefined ? CategoryColor[ProductCategory[category]] : CategoryColor[ProductCategory.EMPTY];
    }
}
