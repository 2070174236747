import { Injectable } from '@angular/core';
import { Product, ProductCategory } from '../models/product';
import { InteriorType, Tile } from '../models/discounter-room';
import { Observable, Subject, map, of, tap, timer } from 'rxjs';
import { RoutesRecognized } from '@angular/router';
import { Position } from '../models/shelf';
import { RouteDijkstra, RouteNode } from '../models/route-node';

export interface RouteTiles {
    entrance?: Tile;
    shelfs?: Tile[];
    ways?: Tile[];
    cashTemrinal?: Tile[];
}

@Injectable({
    providedIn: 'root',
})
export class FoodRouteService {
    tileType = InteriorType;

    dijkstraMaps: RouteDijkstra[] = [];

    findTile?: Tile | null;
    //findTiles:Tile[] = [];
    ispProductTileFound = false;

    recognizeFoodRoute(shoppingCartData: Map<ProductCategory, Product[]>, discountTileMap: Map<string, Tile>): Observable<Map<string, Tile>> {
        const routeTiles = this.getRouteTiles(discountTileMap);
        const targetShelfs = this.getTargetShelfs(routeTiles, shoppingCartData);
        /*const targetShelfs = this.getTargetShelfs(routeTiles, shoppingCartData);
    console.log(discountTileMap);
    console.log(routeTiles);
*/

        //Start with tile entrance
        this.findTile = routeTiles.entrance;

        this.generateDijkstaMapByTargetTiles(routeTiles, targetShelfs);

        const nextFindTile = this.getNextShelfWayTile(routeTiles.ways);
        if (nextFindTile) {
            this.findShortesWayToNextShelf(routeTiles, targetShelfs, nextFindTile);
        }

        return of(discountTileMap);
        /*
    return this.generateRoutePath(
      routeTiles,
      targetShelfs,
      discountTileMap
    ).pipe(
      map((routeWayTiles) => {
        routeWayTiles.forEach((wayTile) => {
          const tileId = wayTile.id;
          if (tileId && discountTileMap.has(tileId)) {
            discountTileMap.set(tileId, wayTile);
          }
        });
        return discountTileMap;
      })
    );*/
    }

    private generateRoutePath(routeTiles: RouteTiles, targetShelfs: Tile[], discountTileMap: Map<string, Tile>): Observable<Tile[]> {
        return timer(500).pipe(
            //Analyze coords (x,y) from tiles
            map(() =>
                //TODO .-> Koordinaten als konstante setzen
                this.getAllRouteTargetTilesWithAbsoluteCoords(routeTiles, targetShelfs)
            ),
            tap(targetTiles => {
                this.generateDijkstaMapByTargetTiles(routeTiles, targetShelfs);
            }),
            tap(() => {
                //this.findShortesWayToNextShelf(routeTiles, targetShelfs);
            })
        );
    }

    private generateDijkstaMapByTargetTiles(routeTile: RouteTiles, targetTiles: Tile[]): void {
        //if (routeTile.entrance && routeTile.ways) {
        console.log(targetTiles);
        if (routeTile.entrance && routeTile.ways) {
            const route = this.generateRouteNodeMapByStartTile(routeTile.ways, targetTiles, 0);
            console.log(route);
            this.dijkstraMaps.push(route);
        }
        return;

        //Iterate through all neccesary tiles (entrynce,shelf and cash terminal), and build route by this collection
        targetTiles.forEach(targetTile => {
            if (routeTile.ways) {
                //Make copy of way tiles
                const copyRouteWayTiles = JSON.parse(JSON.stringify(routeTile.ways));
                const startTargetTile = this.findSmallestWayTileFromTargetTile(targetTile, copyRouteWayTiles);

                /*if (startTargetTile && copyRouteWayTiles) 
          {
            const routeDijkstra = this.generateRouteNodeMapByStartTile(startTargetTile, copyRouteWayTiles, 0);
            this.dijkstraMaps?.push(routeDijkstra);
          }
          */
            }
        });
        //}
    }

    private generateRouteNodeMapByStartTile(
        //tile: Tile,
        ways: Tile[],
        targetShelfs: Tile[],
        index: number
    ): RouteDijkstra {
        const distance = 0;
        const maxDistance = 100;
        let currDistance = 0;
        //let wayCopy = JSON.parse(JSON.stringify(ways));
        let hasNeightbors = true;
        let queue: Tile[] | null = [];
        const fromTiles = new Map<string, Tile>();
        const shortestWayTargetShelf: Tile[] = [];
        const routeDijkstra: RouteDijkstra = {
            route: [],
            map: new Map(),
        };

        if (this.findTile) {
            queue.push(this.findTile);
            while (hasNeightbors && currDistance < maxDistance) {
                let newQueue: Tile[] = [];

                queue.forEach(tile => {
                    if (tile && tile.isNodeChecked !== true) {
                        tile.isNodeChecked = true;
                        tile.distance = currDistance;

                        const shelfNeighbor = this.getNeighbors(tile, targetShelfs, distance);
                        const neightbors = this.getNeighbors(tile, ways, distance);
                        newQueue = newQueue.concat(neightbors);

                        if (shelfNeighbor.length && !this.findTile) {
                            this.findTile = shelfNeighbor[0];
                            /*const isAddable = shortestWayTargetShelf.filter(shelfTile => shelfTile.id === shelfNeighbor[0].id).length === 0;
              if(isAddable){
                shelfNeighbor[0].distance = distance;
                shortestWayTargetShelf.push(shelfNeighbor[0]);
              }*/
                        }

                        if (tile.id) {
                            const fromTile = fromTiles.size === 0 ? tile : fromTiles.get(tile.id);
                            if (fromTile) {
                                const node: RouteNode = {
                                    index: index,
                                    self: tile,
                                    from: fromTile,
                                    neighbors: neightbors,
                                    distance: currDistance,
                                };

                                routeDijkstra.map.set(tile.id, node);
                            }
                        }

                        neightbors.forEach(nextTile => {
                            if (nextTile.id) {
                                fromTiles.set(nextTile.id, tile);
                            }
                        });
                    }
                });
                queue = newQueue;
                if (queue.length === 0) {
                    hasNeightbors = false;
                }
                currDistance++;
            }
        }

        console.log(shortestWayTargetShelf);

        return routeDijkstra;
    }

    private getNextShelfWayTile(ways: Tile[] | undefined): Tile | null {
        if (this.findTile && ways) {
            const findTile = this.findSmallestWayTileFromTargetTile(this.findTile, ways);
            return findTile;
        }

        return null;
    }

    private findShortesWayToNextShelf(routeTiles: RouteTiles, targetShelfs: Tile[], findNextTile: Tile) {
        console.log(this.dijkstraMaps);

        if (this.dijkstraMaps && this.dijkstraMaps.length > 0) {
            const findProductId = findNextTile?.id;
            const entrance = routeTiles.entrance;
            const ways = routeTiles.ways;
            const routeDijkstra = this.dijkstraMaps[0];

            if (entrance && ways && findProductId) {
                const startTile = this.findSmallestWayTileFromTargetTile(entrance, ways);
                let currRouteNode = routeDijkstra.map.get(findProductId);

                if (currRouteNode) {
                    currRouteNode.self.isMarkRoute = true;
                    let foundFirstTile = false;
                    const maxIteration = 1000;
                    let curIteration = 0;

                    while (!foundFirstTile && curIteration < maxIteration) {
                        curIteration++;
                        if (currRouteNode && currRouteNode.from) {
                            const prevId = currRouteNode.from.id;

                            if (prevId && startTile?.id !== currRouteNode.self.id) {
                                const direction = this.getRouteDirectionByTiles(currRouteNode);
                                currRouteNode = routeDijkstra.map.get(prevId);
                                ways.find(way => way.id === prevId);

                                ways.forEach(tile => {
                                    if (tile.id === prevId) {
                                        tile.isMarkRoute = true;
                                        tile.routeDirection = direction;
                                    }
                                });
                            } else {
                                foundFirstTile = true;
                            }
                        }
                    }
                }
            }
        }
    }

    getNeighbors(tile: Tile, ways: Tile[], dist: number): Tile[] {
        const neightbors: Tile[] = [];

        ways.forEach(wayTile => {
            if (tile.id !== wayTile.id && wayTile?.isNodeChecked !== true) {
                if (
                    (tile.x + 1 === wayTile.x && tile.y === wayTile.y) ||
                    (tile.x - 1 === wayTile.x && tile.y === wayTile.y) ||
                    (tile.x === wayTile.x && tile.y + 1 === wayTile.y) ||
                    (tile.x === wayTile.x && tile.y - 1 === wayTile.y)
                ) {
                    neightbors.push(wayTile);
                    wayTile.distance = dist;
                }
            }
        });

        return neightbors;
    }

    private findSmallestWayTileFromTargetTile(targetTile: Tile, ways: Tile[]): Tile | null {
        let foundTile: Tile | null = null;
        ways.filter(way => {
            if (
                (foundTile == null && targetTile.x + 1 === way.x && targetTile.y === way.y) ||
                (targetTile.x - 1 === way.x && targetTile.y === way.y) ||
                (targetTile.x === way.x && targetTile.y + 1 === way.y) ||
                (targetTile.x === way.x && targetTile.y - 1 === way.y)
            ) {
                foundTile = way;
            }
        });

        return foundTile;
        /*console.log(tilePos);


    let distToEntrance = -1;
    let findTile = null;
    ways?.forEach((way) => {
      const currDist = this.getDistance(targetTile, way);

      if (distToEntrance === -1 || currDist < distToEntrance) {
        distToEntrance = currDist;
        findTile = way;
      }
    });
    return findTile;*/
    }

    private getRouteTiles(discountTileMap: Map<string, Tile>): RouteTiles {
        const routeTiles: RouteTiles = {
            entrance: undefined,
            shelfs: [],
            ways: [],
            cashTemrinal: [],
        };

        discountTileMap.forEach(tile => {
            if (tile.type === this.tileType.ENTRANCE) {
                routeTiles.entrance = tile;
            }

            if (tile.type === this.tileType.SHELF && tile.shelf && tile.shelf.level?.length > 0) {
                if (routeTiles.shelfs) {
                    routeTiles.shelfs.push(tile);
                }
            }

            if (tile.type === this.tileType.WAY && routeTiles.ways) {
                routeTiles.ways.push(tile);
            }

            if (tile.type === this.tileType.CASH_TERMINAL && routeTiles.cashTemrinal) {
                routeTiles.cashTemrinal.push(tile);
            }
        });
        return routeTiles;
    }

    getTargetShelfs(routeTiles: RouteTiles, userShoppingCartData: Map<ProductCategory, Product[]>): Tile[] {
        const tagetShelfsTiles = routeTiles.shelfs?.filter(shelfTile => {
            let shelfContainsUserProduct = false;
            shelfTile.shelf?.level.forEach(level => {
                if (!shelfContainsUserProduct && level.product) {
                    userShoppingCartData.forEach(productCategory => {
                        if (!shelfContainsUserProduct) {
                            productCategory.forEach(shoppingCartProductProduct => {
                                if (shoppingCartProductProduct.id === level.product.id) {
                                    shelfContainsUserProduct = true;
                                }
                            });
                        }
                    });
                }
            });
            return shelfContainsUserProduct;
        });
        return tagetShelfsTiles ? tagetShelfsTiles : [];
    }

    private getRouteDirectionByTiles(currRouteNode: RouteNode): string {
        let direction = '';
        if (currRouteNode.from && currRouteNode.self) {
            const xyCoordFrom = this.getXYCoord(currRouteNode.from);
            const xyCoordSelf = this.getXYCoord(currRouteNode.self);

            if (xyCoordFrom.x - 1 === xyCoordSelf.x && xyCoordFrom.y === xyCoordSelf.y) {
                direction = 'LEFT';
            }

            if (xyCoordFrom.x + 1 === xyCoordSelf.x && xyCoordFrom.y === xyCoordSelf.y) {
                direction = 'RIGHT';
            }

            if (xyCoordFrom.x === xyCoordSelf.x && xyCoordFrom.y - 1 === xyCoordSelf.y) {
                direction = 'TOP';
            }

            if (xyCoordFrom.x === xyCoordSelf.x && xyCoordFrom.y + 1 === xyCoordSelf.y) {
                direction = 'BOTTOM';
            }
        }

        return direction;
    }

    //Return EntranceTile, ProductTargetTiles and CasHTerminalTile with absolute x and y coords
    private getAllRouteTargetTilesWithAbsoluteCoords(routeTiles: RouteTiles, targetShelfs: Tile[]): Tile[] {
        const pathTiles: Tile[] = [];

        //Set entrance Tile
        if (routeTiles.entrance) {
            const entrance = this.getUpdatedTileWithXAndYCoord(routeTiles.entrance);
            pathTiles.push(entrance);
        }

        targetShelfs.forEach(tile => {
            const targetTile = this.getUpdatedTileWithXAndYCoord(tile);
            pathTiles.push(targetTile);
        });

        if (routeTiles.ways) {
            routeTiles.ways.forEach((tile: Tile, index: number) => {
                const wayTile: Tile = this.getUpdatedTileWithXAndYCoord(tile);
                if (routeTiles.ways) {
                    routeTiles.ways[index] = wayTile;
                }
            });
        }

        //Set first cash-terminal Tile
        // TODO -> the shortes cash terminal should be use, not simply the first cashTemrinal -> 0
        if (routeTiles.cashTemrinal) {
            const cashTerminalTile = this.getUpdatedTileWithXAndYCoord(routeTiles.cashTemrinal[0]);
            pathTiles.push(cashTerminalTile);
        }
        return pathTiles;
    }

    private getUpdatedTileWithXAndYCoord(tile: Tile): Tile {
        const id = tile.id;
        if (id) {
            const tileElement = document.getElementById(id);
            if (tileElement) {
                const elementPosition = tileElement.getBoundingClientRect();
                tile.x = elementPosition.x;
                tile.y = elementPosition.y;
            }
        }
        return tile;
    }

    private getDistance(tileFrom: Tile, tileTo: Tile): number {
        return Math.floor(Math.sqrt(Math.pow(tileTo.x - tileFrom.x, 2) + Math.pow(tileTo.y - tileFrom.y, 2)));
    }

    private getXYCoord(tileFrom: Tile): Position {
        const splitCoord = tileFrom.id?.split('-');
        if (splitCoord) {
            return { x: parseInt(splitCoord[0]), y: parseInt(splitCoord[1]) };
        }
        return { x: 0, y: 0 };
    }
}
