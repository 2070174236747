import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, filter, finalize, map, of, switchMap, takeUntil, tap, timer } from 'rxjs';
import { Product, ProductCategory } from 'src/app/models/product';
import { ProductsService } from 'src/app/services/products.service';

export interface SimpleProduct {
    id?: number;
    n: string;
    c?: number;
    ca: ProductCategory;
}
@Component({
    selector: 'app-input-product-search',
    templateUrl: './input-product-search.component.html',
    styleUrls: ['./input-product-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputProductSearchComponent implements OnChanges {
    @Input() dayIndex?: number;
    @Input() updateProduct?: Product | null;
    @Output() addProduct: EventEmitter<SimpleProduct> = new EventEmitter<SimpleProduct>();
    @Output() blurUpdate: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('inputSearch') inputSearch!: ElementRef;
    @ViewChild('inputCount') inputCount!: ElementRef;

    inputPorudtcSearch = new FormControl();
    inputPorudtcCnt = new FormControl(1);

    searchResultArr$ = new Observable<SimpleProduct[] | null>();
    isAddProduct = false;
    isUpdateProduct = false;
    searchProductExist = true;
    isLoadingProduct$ = new BehaviorSubject(false);

    currentAddedSImpleProduct?: SimpleProduct;

    constructor(
        private productService: ProductsService,
        private renderer: Renderer2,
        private destroyRef: DestroyRef
    ) {
        this.searchResultArr$ = this.inputPorudtcSearch.valueChanges.pipe(
            filter(input => !this.isUpdateProduct && input !== this.currentAddedSImpleProduct?.n),
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => {
                this.searchProductExist = true;
                this.isLoadingProduct$.next(true);
            }),
            switchMap(input => this.productService.filterProducts(input as string)),
            tap(products => {
                this.isLoadingProduct$.next(false);
                if (this.inputPorudtcSearch && this.inputPorudtcSearch.value.length > 0 && products.length === 0) {
                    this.searchProductExist = false;
                }
            })
        );
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges['updateProduct']) {
            const product: Product = simpleChanges['updateProduct'].currentValue;
            this.setUpdateProduct(product);
        }
    }

    setUpdateProduct(product: Product): void {
        if (product && this.inputPorudtcSearch) {
            this.inputPorudtcSearch.disable();
            this.isUpdateProduct = true;
            const cnt = product.cnt ? product.cnt : 1;
            this.inputPorudtcCnt.setValue(cnt);
            this.inputPorudtcSearch.setValue(product.name);

            this.currentAddedSImpleProduct = {
                id: product.id,
                n: product.name,
                ca: product.category,
            };

            this.inputCount?.nativeElement.focus();
            this.inputCount?.nativeElement.select();
        } else {
            this.resetInputSearch();
        }
    }

    btnAddProduct(): void {
        let count = this.inputPorudtcCnt.value ?? 1;
        count = typeof count === 'number' ? count : parseInt(count);

        //if ((this.searchProductExist || this.isUpdateProduct) && this.currentAddedSImpleProduct && this.inputPorudtcSearch.value && this.inputPorudtcCnt.value) {
        if ((this.searchProductExist || this.isUpdateProduct) && this.currentAddedSImpleProduct) {
            this.currentAddedSImpleProduct.c = count;
            if (this.isUpdateProduct) {
                this.currentAddedSImpleProduct.id = this.updateProduct?.id;
            }

            this.addProduct.emit(this.currentAddedSImpleProduct);
        } else {
            const productName = this.inputPorudtcSearch.getRawValue();
            const unkownProduct: SimpleProduct = {
                n: productName,
                ca: ProductCategory.EMPTY,
                c: count,
            };
            this.productService.showNewProductPanel(unkownProduct);
        }
        this.resetInputSearch();
        //this.inputSearch?.nativeElement.focus();
    }

    resetInputSearch(): void {
        //this.inputPorudtcSearch.reset();
        this.inputPorudtcSearch.setValue('');
        this.inputPorudtcCnt.setValue(1);
        this.isUpdateProduct = false;
        this.inputPorudtcSearch.enable();
        this.searchProductExist = true;
        this.updateProduct = null;
    }

    selectProductById(product: SimpleProduct): void {
        this.inputPorudtcSearch.setValue('');
        this.currentAddedSImpleProduct = product;
        this.isAddProduct = true;
        //this.inputPorudtcSearch.reset();
        //this.inputPorudtcSearch.setValue(product.n);
        //this.searchResultArr$ = of([]);

        this.btnAddProduct();
        //document.getElementById('day-index-' + this.dayIndex)?.focus();
    }

    onBlur(): void {
        console.log('-------------');
        console.log(this.isUpdateProduct);
        if (this.isUpdateProduct) {
            this.isUpdateProduct = false;
            timer(250)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(() => {
                    console.log(this.isUpdateProduct);
                    if (!this.isUpdateProduct) {
                        //if (this.inputPorudtcSearch.value.length === 0) {
                        this.blurUpdate.emit();
                    }
                    //  this.searchProductExist = true;
                    //}
                    //}
                    //this.resetInputSearch();
                });
        }
    }

    scrollScreen() {
        // timer(300).subscribe(() => {
        //     const focusOffset = 0;
        //     const inputPosition = document.getElementById('day-index-' + this.dayIndex) as HTMLElement; //?.getBoundingClientRect().top; // this.inputSearch.nativeElement as HTMLElement;
        /*   const scrollOffset = window.scrollY;
        console.log(inputPosition + ' - ' + scrollOffset);
        const scrollToPosition = scrollOffset - (scrollOffset - inputPosition!) + focusOffset;
        console.log(scrollToPosition);
       */
        //     if (inputPosition) {
        //inputPosition.scrollIntoView({ block: 'start', behavior: 'smooth' });
        //    }
        //this.renderer.setProperty(document.documentElement, 'scrollTop', scrollToPosition);
        //});
    }
}
