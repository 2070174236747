import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { Observable, map, switchMap, tap } from 'rxjs';
import { Product } from 'src/app/models/product';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';

export interface Products {
    products: Product[];
}

export interface WeekShopping {
    mo: Products[];
    tue: Products[];
    wed: Products[];
    thu: Products[];
    fri: Products[];
    sa: Products[];
    so: Products[];
}

export interface DayShoppingList {
    shoppingList: Product[];
}

export interface WeekShoppingData {
    week: DayShoppingList[];
}

export interface ResponseWeekShoppingList {
    data: WeekShoppingData;
}

@Component({
    selector: 'app-week-shopping-list',
    templateUrl: './week-shopping-list.component.html',
    styleUrls: ['./week-shopping-list.component.scss'],
})
export class WeekShoppingListComponent {
    currOpenDayIndex: number = -1;
    weekId?: string;
    weekShoppingList$: Observable<WeekShoppingData> = new Observable();

    constructor(
        private shoppingCartService: ShoppingCartService,
        router: ActivatedRoute
    ) {
        this.weekShoppingList$ = router.paramMap.pipe(
            map((param: ParamMap) => param.get('weekId')!),
            switchMap((weekdId: string) => this.shoppingCartService.loadShoppingList(weekdId)),
            switchMap(() => this.shoppingCartService.getWeekShoppingData())
        );
    }

    toggleDay(index: number): void {
        console.log(index);
        this.currOpenDayIndex = index;
    }
}
