<ng-container *ngIf="newProduct$ | async">
    <div class="shopping-lightbox">
        <div class="shopping-lightbox-section">
            <div class="shopping-lightbox-header">
                <h2>Produkt hinzufügen</h2>
                <p>Dieses Proukt scheint noch nicht zu existieren, es muss daher Neu angekegt werden.</p>
            </div>
            <div class="shopping-lightbox-content">
                <form [formGroup]="productForm">
                    <div class="row">
                        <span>
                            <label>Produktname</label>
                        </span>
                        <input formControlName="n" type="text" placeholder="Apfel ..." />
                    </div>
                    <div class="row">
                        <span>
                            <label>Kategorie</label>
                        </span>
                        <select formControlName="ca">
                            <option value="-">- Wählen -</option>
                            <ng-container *ngIf="categories$ | async as categories">
                                <option *ngFor="let categorie of categories" [value]="categorie.id">
                                    {{ categorie.name }}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="row">
                        <span>
                            <label>Preis</label>
                        </span>
                        <input formControlName="p" type="text" placeholder="1.99" />
                    </div>
                </form>
            </div>
            <div class="shopping-lightbox-footer">
                <button (click)="close()" class="btn-secondary" type="button"><i class="fa fa-times" aria-hidden></i> Schließen</button>
                <button (click)="generateProduct()" class="btn-primary" type="button"><i class="fa fa-gear" aria-hidden></i> Erstellen</button>
            </div>
        </div>

        <div class="shopping-lightbox-filter"></div>
    </div>
</ng-container>
