<div class="content">
    <header>
        <h2 class="title"><i class="fa-solid fa-cart-shopping"></i> Einkaufscheck</h2>
        <p>Häckchen dran.</p>
    </header>
    <ng-container *ngIf="shoppingCart$ | async as shoppingCart">
        <ng-container *ngIf="isWeekShoppingCart">
            <h2 class="title"><i class="fa-solid fa-cart-shopping"></i> Checkliste - Enkaufszettel</h2>
        </ng-container>
        <ng-container *ngIf="!isWeekShoppingCart">
            <h2 class="title"><i class="fa-solid fa-cart-shopping"></i> Checkliste - Wochenenkauf</h2>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="shoppingCartData$ | async as shoppingCartData">
        <ul>
            <li *ngFor="let categorie of shoppingCartData.entries()" class="carty-category">
                <label [ngStyle]="{ 'background-color': categorie[0] | productCategoryColor }"
                    ><i class="fa" [ngClass]="categorie[0] | productCategoryIcon"></i> {{ categorie[0] | productCategoryName }}</label
                >
                <ul>
                    <li (click)="toggleProduct(product)" *ngFor="let product of categorie[1]; let i = index; trackBy: trackByIndex" class="tile-wrapper">
                        <div class="tile" [ngClass]="{ 'product-checked': product.check }">
                            <div class="tile-content" [attr.aria-label]="product.cnt + 'mal' + product.name">
                                <span class="text"><span class="line" [ngClass]="{ checked: checked }"></span>{{ product.cnt }} <i class="fa fa-times" aria-hidden="true"></i> {{ product.name }}</span>
                            </div>
                            <div class="tile-actions">
                                <button class="btn">
                                    <i *ngIf="product.check" class="fa-regular fa-square-check"></i>
                                    <i *ngIf="!product.check" class="fa-regular fa-square"></i>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </ng-container>
</div>
