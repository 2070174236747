<div class="content">
    <h2 class="title"><i class="fa-solid fa-screwdriver-wrench"></i> Food Route Editor</h2>
    <div class="editor">
        <div class="editor-info-section">
            <div class="shelf-section">
                <ng-container *ngIf="currEditorMode === editorMode.CONSTRUCT_MODE">
                    <h2>1. Discounter Grundriss</h2>
                    <p>Zeichnung des Grundrisses des Discounters mit Wegen und dessen Regalen.</p>
                </ng-container>

                <ng-container *ngIf="currEditorMode === editorMode.NODE_CORNER_MODE">
                    <h2>2. Knoten Punkte setzen</h2>
                    <p>Für die Routen Berechnung ist es Notwendig Kreuzungen und Eckpunkte der Discounter Map zu setzen.</p>
                    <app-editor-node-corners [discounter]="discounter"></app-editor-node-corners>
                </ng-container>

                <ng-container *ngIf="currEditorMode === editorMode.PRODUCT_MODE">
                    <h2>3. Regal Details</h2>
                    <p>Klicken Sie auf eine Regal Kachel un fügen sie mehrere Regalebene hinzu, wo sie anschließend die Produkte hinzugefügt werden können.</p>
                    <app-shelf [shelf]="selectedShelf" (emitChangeShelf)="changeShelf($event)"></app-shelf>
                </ng-container>
            </div>
        </div>
        <div class="editor-panel-section">
            <div class="editor-mode-section">
                <ul>
                    <li>
                        <button
                            (click)="setEditorMode(editorMode.CONSTRUCT_MODE)"
                            [ngClass]="{
                                'selected-editor-mode': currEditorMode === editorMode.CONSTRUCT_MODE
                            }">
                            <i class="fa-solid fa-compass-drafting"></i> 1. Discounter Grundriss
                        </button>
                    </li>
                    <li>
                        <button
                            (click)="setEditorMode(editorMode.NODE_CORNER_MODE)"
                            [ngClass]="{
                                'selected-editor-mode': currEditorMode === editorMode.NODE_CORNER_MODE
                            }">
                            <i class="fa-solid fa-circle-nodes"></i> 2. Knoten Punkte setzen
                        </button>
                    </li>
                    <li>
                        <button
                            (click)="setEditorMode(editorMode.PRODUCT_MODE)"
                            [ngClass]="{
                                'selected-editor-mode': currEditorMode === editorMode.PRODUCT_MODE
                            }">
                            <i class="fa-solid fa-wheat-awn-circle-exclamation"></i>
                            3. Regal einräumen
                        </button>
                    </li>
                </ul>
            </div>

            <div class="editor-table-section">
                <table
                    id="table-dicsounter-map"
                    [ngClass]="{
                        'is-construct-mode': currEditorMode === editorMode.CONSTRUCT_MODE,
                        'is-node-corner-mode': currEditorMode === editorMode.NODE_CORNER_MODE,
                        'is-product-mode': currEditorMode === editorMode.PRODUCT_MODE
                    }">
                    <tr *ngFor="let row of [].constructor(discounter.height); let y = index">
                        <td *ngFor="let column of [].constructor(discounter.width); let x = index" (mousedown)="startDraw(x, y)" (mouseup)="stopDraw(x, y)" (mouseover)="draw(x, y)">
                            <ng-container *ngIf="isTileSelected(x, y) as tile">
                                <div
                                    [id]="tile.id"
                                    (click)="setProductToShelf(tile)"
                                    (keydown)="multiSelectionKey($event)"
                                    class="tile"
                                    [ngClass]="{
                                        'tile-type-shelf': tile.type === tileType.SHELF,
                                        'tile-type-way': tile.type === tileType.WAY,
                                        'tile-selected': isTileInMultiSelection(tile),
                                        'tile-is-route': tile.type === tileType.WAY && tile?.isMarkRoute,
                                        'tile-type-way-node-editor': currEditorMode === editorMode.NODE_CORNER_MODE,
                                        'tile-type-way-node-is-set': tile.isNodeCorner
                                    }"
                                    [title]="tile?.shelf?.level | json"
                                    [ngStyle]="{
                                        'background-color': tile?.shelf?.category | productCategoryColor
                                    }">
                                    <!--ENTRANCE TILES-->
                                    <i *ngIf="tile.type === tileType.ENTRANCE" class="fa-solid fa-door-open"></i>
                                    <!--WAY TILES WITH DIFFRENT DIRECTIONS-->
                                    <i *ngIf="tile.type === tileType.WAY && !tile?.isMarkRoute" class="fa-regular fa-square"></i>
                                    <i *ngIf="tile.type === tileType.WAY && tile?.isMarkRoute && tile?.routeDirection === 'TOP'" class="fa-solid fa-square-caret-up"></i>
                                    <i *ngIf="tile.type === tileType.WAY && tile?.isMarkRoute && tile?.routeDirection === 'BOTTOM'" class="fa-solid fa-square-caret-down"></i>
                                    <i *ngIf="tile.type === tileType.WAY && tile?.isMarkRoute && tile?.routeDirection === 'RIGHT'" class="fa-solid fa-square-caret-right"></i>
                                    <i *ngIf="tile.type === tileType.WAY && tile?.isMarkRoute && tile?.routeDirection === 'LEFT'" class="fa-solid fa-square-caret-left"></i>
                                    <!--CASG TERMINAL TILES-->
                                    <i *ngIf="tile.type === tileType.CASH_TERMINAL" class="fa-solid fa-cash-register"></i>

                                    <ng-container *ngIf="tile.type === tileType.SHELF">
                                        <span class="level-counter">
                                            {{ tile?.shelf?.level?.length }}
                                        </span>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="toolbar-section">
                <ul *ngIf="currEditorMode === editorMode.PRODUCT_MODE">
                    <li>
                        <button (click)="changeProductMode('SET_PRODUCT')" title="Proukte von einem Regal ins andere Tauschen">
                            <i class="fa-solid fa-plus"></i>
                            <small>Setzen</small>
                        </button>
                    </li>
                    <li>
                        <button (click)="changeProductMode('EXCHANGE_PRODUCT')" title="Proukte von einem Regal ins andere Tauschen">
                            <i class="fa-solid fa-shuffle"></i>
                            <small>Tauschen</small>
                        </button>
                    </li>
                </ul>

                <ul *ngIf="currEditorMode === editorMode.NODE_CORNER_MODE">
                    <li>
                        <button
                            (click)="selectToolNode(toolType.NODE_CORNER_ADD)"
                            [ngClass]="{
                                'is-selected': selectedNodeTool === toolType.NODE_CORNER_ADD
                            }">
                            <i class="fa-solid fa-circle-plus"></i>
                            <small>Hinzufügen</small>
                        </button>
                    </li>
                    <li>
                        <button
                            (click)="selectToolNode(toolType.NODE_CORNER_DELETE)"
                            [ngClass]="{
                                'is-selected': selectedNodeTool === toolType.NODE_CORNER_DELETE
                            }">
                            <i class="fa-solid fa-door-open"></i>
                            <small>Löschen</small>
                        </button>
                    </li>
                </ul>

                <ul *ngIf="currEditorMode === editorMode.CONSTRUCT_MODE">
                    <li>
                        <button
                            (click)="selectTool(tileType.ENTRANCE)"
                            [ngClass]="{
                                'is-selected': selectedTool === tileType.ENTRANCE
                            }">
                            <i class="fa-solid fa-door-open"></i>
                            <small>Eingang</small>
                        </button>
                    </li>
                    <li>
                        <button
                            (click)="selectTool(tileType.WAY)"
                            [ngClass]="{
                                'is-selected': selectedTool === tileType.WAY
                            }">
                            <i class="fa-regular fa-square"></i>
                            <small>Weg</small>
                        </button>
                    </li>
                    <li>
                        <button
                            (click)="selectTool(tileType.SHELF)"
                            [ngClass]="{
                                'is-selected': selectedTool === tileType.SHELF
                            }">
                            <i class="fa-solid fa-square-full"></i>
                            <small>Regal</small>
                        </button>
                    </li>
                    <li>
                        <button
                            (click)="selectTool(tileType.CASH_TERMINAL)"
                            [ngClass]="{
                                'is-selected': selectedTool === tileType.CASH_TERMINAL
                            }">
                            <i class="fa-solid fa-cash-register"></i>
                            <small>Kasse</small>
                        </button>
                    </li>
                    <li>
                        <button (click)="selectDeleteTool()" [ngClass]="{ 'is-selected': isDelete }">
                            <i class="fa-solid fa-eraser"></i>
                            <small>Löschen</small>
                        </button>
                    </li>
                </ul>

                <ul>
                    <li>
                        <button (click)="saveDiscount()">
                            <i class="fa-regular fa-floppy-disk"></i>
                            <small>Speichern</small>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="editor-info-section">
            <h2>Produkliste</h2>
            <div class="table-products">
                <table>
                    <thead>
                        <tr>
                            <th>Pos</th>
                            <th>Name</th>
                            <th>Preis</th>
                            <th>Kategorie</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="products$ | async as products" class="products-list">
                        <tr *ngFor="let product of products; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ product.name }}</td>
                            <td>{{ product.price | currency: 'EUR' }}</td>
                            <td>{{ product.category }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<!--
<ul class="list-toolbox">
    <li *ngFor="let colorType of colorTypes$ | async" class="WALL">Wall</li>
</ul>
-->
