import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { ShoppingList } from '../models/shopping-list';
@Injectable({
    providedIn: 'root',
})
export class LocalDataService {
    getData(key: string): string {
        return this.getItem('QM_' + key);
    }

    setData(key: string, data: any): void {
        this.setItem('QM_' + key, data);
    }

    getUserKey(): string {
        return this.getItem(env.localDataKeyStore.userKey);
    }

    setUserKey(userKey: string): void {
        this.setItem(env.localDataKeyStore.userKey, userKey);
    }

    removeUserKey(): void {
        this.removeItem(env.localDataKeyStore.userKey);
    }

    getShoppingListCollection(): ShoppingList[] {
        const data = this.getItem(env.localDataKeyStore.shoppingListCollectionKey);
        return JSON.parse(data);
    }

    setShoppingListCollection(shoppingListCollection: ShoppingList[]): void {
        const shoppingListCollectionString = JSON.stringify(shoppingListCollection);
        this.setItem(env.localDataKeyStore.shoppingListCollectionKey, shoppingListCollectionString);
    }

    getSelectedShoppingListId(): string {
        return this.getItem(env.localDataKeyStore.selectedShoppingListKey);
    }

    setSelectedShoppingListId(selectedShoppingListId: string): void {
        this.setItem(env.localDataKeyStore.selectedShoppingListKey, selectedShoppingListId);
    }

    getShoppingListKey(): string {
        return this.getItem(env.localDataKeyStore.selectedShoppingListKey);
    }

    setShoppingLIstKey(shoppingListKey: string): void {
        this.setItem(env.localDataKeyStore.selectedShoppingListKey, shoppingListKey);
    }

    removeShoppingListKey(): void {
        this.removeItem(env.localDataKeyStore.selectedShoppingListKey);
    }

    removeAllKeys(): void {
        this.removeUserKey();
        this.removeShoppingListKey();
    }

    private setItem(key: string, value: string): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    private getItem(key: string): string {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    private removeItem(key: string): void {
        localStorage.removeItem(key);
    }
}
