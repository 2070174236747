<div class="product-search-section">
    <form>
        <div class="product-search-input" [id]="'day-index-' + dayIndex">
            <input #inputCount [formControl]="inputPorudtcCnt" (blur)="onBlur()" class="input-product-cnt" type="text" placeholder="1" name="productCount" />
            <input #inputSearch [formControl]="inputPorudtcSearch" (blur)="onBlur()" class="input-product-name" type="text" placeholder="z.B. Apfel" name="productSearch" />
            <div *ngIf="isLoadingProduct$ | async" class="spinner"><i class="fa fa-refresh fa-spin"></i></div>
            <button *ngIf="updateProduct" class="btn-tertiary" (click)="btnAddProduct()">
                <i class="fa fa-repeat"></i>
            </button>
        </div>
    </form>
</div>
<ng-container *ngIf="searchResultArr$ | async as searchResultArr">
    <div style="position: relative">
        <ul class="product-search-list">
            <li *ngFor="let product of searchResultArr" (click)="selectProductById(product)">
                <span
                    class="search-category"
                    [ngStyle]="{
                        backgroundColor: product.ca | productCategoryColor
                    }">
                    <i class="fa" [ngClass]="product.ca | productCategoryIcon"></i>
                </span>
                <span class="search-category-name">{{ product.n }}</span>
            </li>
            <li *ngIf="!searchProductExist && !isUpdateProduct" class="not-found-product">
                <span class="search-category">
                    <i class="fa-regular fa-circle-question"></i>
                </span>
                <span class="search-category-name">Keine Produkt gefunden</span>
                <button *ngIf="!updateProduct" class="btn-primary" (click)="btnAddProduct()">
                    <i class="fa fa-plus"></i>
                </button>
            </li>
        </ul>
    </div>
</ng-container>
