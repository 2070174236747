<div class="content">
    <h2 class="title"><i class="fa-solid fa-list"></i> Wochen Listen</h2>

    <hr />

    <ul>
        <li *ngFor="let weekShoppingListFile of weekShoppingListsFiles$ | async" (click)="goToWeekShoppingList(weekShoppingListFile)" class="old-week week-shopping-list-file">
            <span class="list-icon">
                <i class="fa fa-list-ul"></i>
            </span>

            <div class="week-shopping-data">
                <span class="week-data">
                    <small>Woche</small>
                    {{ weekShoppingListFile.w }}
                </span>

                <span class="week-data">
                    <small>Start</small>
                    {{ weekShoppingListFile.from }}
                </span>

                <span class="week-data">
                    <small>Ende</small>
                    <span>
                        {{ weekShoppingListFile.to }}
                    </span>
                </span>
            </div>
        </li>
    </ul>
</div>
