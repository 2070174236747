import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { LocalDataService } from './local-data.service';
import { environment as env } from 'src/environments/environment';
import { Router } from '@angular/router';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private localData: LocalDataService,
        private router: Router
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        /*if (request.url.indexOf('login') === -1) {
            const userKey = this.localData.getUserKey();
            alert(userKey)
            if (userKey) {
                request = request.clone({
                    setHeaders: {
                        Authorization: userKey,
                    },
                });
            } else {
                this.router.navigate(['/not-authorized']);
                return EMPTY;
            }
        }*/

        return next.handle(request);
    }
}
