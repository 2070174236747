import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, tap, timer } from 'rxjs';
import { NotificationAction, NotificationDuration, NotificationTyp } from 'src/app/models/notification';
import { ShoppingCart } from 'src/app/models/shopping-cart';
import { ShoppingList } from 'src/app/models/shopping-list';
import { NotificationService } from 'src/app/services/notification.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { environment as env } from 'src/environments/environment';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
    allShoppingCartList$ = new Observable<ShoppingList[]>();
    selectedShoppingList$ = new Observable<ShoppingList | null>();
    selectedShoppingListProductCnt = 0;
    isGenerateNewShoppingList: boolean = false;
    isGenerateNewWeekShoppingList: boolean = false;

    constructor(
        private shoppingCartService: ShoppingCartService,
        private notificationService: NotificationService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private destroyRef: DestroyRef
    ) {
        this.activeRoute.queryParams.subscribe(params => {
            const shoppingLink = params['link'];
            if (shoppingLink) {
                this.shoppingCartService.loadShoppingListByLink(params['link']).subscribe(() => {
                    this.router.navigateByUrl('shopping-cart');
                });
            }
        });

        this.allShoppingCartList$ = this.shoppingCartService.getAllShoppingLists();
        this.selectedShoppingList$ = this.shoppingCartService.getSelectedShoppingList().pipe(
            tap(shoppingList => {
                if (shoppingList?.productList) {
                    this.selectedShoppingListProductCnt = shoppingList.productList.length;
                } else {
                    this.selectedShoppingListProductCnt =
                        shoppingList?.weekProductList?.reduce((acc, dayProducts) => {
                            return (acc += dayProducts.length);
                        }, 0) || 0;
                }
            })
        );
    }

    addNewShoppingCart(): void {
        this.isGenerateNewShoppingList = true;
        this.shoppingCartService.generateShoppingCart();
        timer(300)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.router.navigateByUrl('shopping-list');
            });
    }

    addNewWeekShoppingCart(): void {
        this.isGenerateNewWeekShoppingList = true;
        this.shoppingCartService.generateWeekShoppingCart();
        timer(300)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.router.navigateByUrl('shopping-list');
            });
    }

    goToShoppingCart(shoppingCart: ShoppingCart): void {
        this.shoppingCartService.setSelectedShoppingListById(shoppingCart.id);
        this.router.navigateByUrl('shopping-list');
    }

    deleteShoppingList(shoppingList: ShoppingList): void {
        this.shoppingCartService.deleteShoppingList(shoppingList);
        this.notificationService.notify({ type: NotificationTyp.INFO, msg: 'Einkaufsliste wurde entfernt', duration: NotificationDuration.SHORT });
    }

    generateShoppingListLink(shoppingList: ShoppingList, index: number): void {
        this.shoppingCartService.generateShoppingLink(shoppingList).subscribe(link => {
            const text = env.homeUrl + '/#/home?link=' + link;
            this.notificationService.notify({ type: NotificationTyp.INFO, msg: 'Einkaufslink speichern und versenden', copyMsg: text, action: NotificationAction.COPY });
        });
    }
}
