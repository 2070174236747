import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscounterRoom, Tile } from 'src/app/models/discounter-room';
import { FoodRouteService } from 'src/app/services/food-route.service';

@Component({
    selector: 'app-editor-node-corners',
    templateUrl: './editor-node-corners.component.html',
    styleUrls: ['./editor-node-corners.component.scss'],
})
export class EditorNodeCornersComponent implements OnChanges {
    @Input() discounter?: DiscounterRoom | null;

    tileNodes: Tile[] = [];
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['discounter']) {
            console.log(changes['discounter'].currentValue);
            this.initTileNodes(changes['discounter'].currentValue.enviromentTiles);
        }
    }

    private initTileNodes(tiles: Map<string, Tile>) {
        this.tileNodes = [];
        tiles.forEach(tile => {
            if (tile.isNodeCorner) {
                this.tileNodes.push(tile);
            }
        });
        console.log('ALL TILE NODES');
        console.log(this.tileNodes);
    }
}
