import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TileIndexPipe } from './pipes/tile-index.pipe';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ProductCategoryIconPipe } from './pipes/product-category-icon.pipe';
import { LoginComponent } from './screens/login/login.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { NotAuthorizedComponent } from './screens/not-authorized/not-authorized.component';
import { AddProductPanelComponent } from './components/add-product-panel/add-product-panel.component';
import { InputComponent } from './components/input/input.component';
import { LogoComponent } from './components/logo/logo.component';
import { ShoppingListsComponent } from './screens/shopping-lists/shopping-lists.component';
import { WeekShoppingListComponent } from './screens/week-shopping-list/week-shopping-list.component';
import { DayShoppingListComponent } from './components/day-shopping-list/day-shopping-list.component';
import { InputProductSearchComponent } from './components/input-product-search/input-product-search.component';
import { ProductCategoryColorPipe } from './pipes/product-category-color.pipe';
import { InfoHeaderComponent } from './components/info-header/info-header.component';
import { ShoppingCartComponent } from './screens/shopping-cart/shopping-cart.component';
import { FoodRouteComponent } from './components/food-route/food-route.component';
import { RouteEditorComponent } from './screens/route-editor/route-editor.component';
import { ShelfComponent } from './components/shelf/shelf.component';
import { EditorNodeCornersComponent } from './components/editor-node-corners/editor-node-corners.component';
import { HomeComponent } from './screens/home/home.component';
import { ShoppingListComponent } from './screens/shopping-list/shopping-list.component';
import { ProductCategoryNamePipe } from './pipes/product-category-name.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NotificationComponent } from './components/notification/notification.component';

@NgModule({
    declarations: [
        AppComponent,
        TileIndexPipe,
        NavigationComponent,
        ProductCategoryIconPipe,
        LoginComponent,
        NotAuthorizedComponent,
        AddProductPanelComponent,
        InputComponent,
        LogoComponent,
        ShoppingListsComponent,
        WeekShoppingListComponent,
        DayShoppingListComponent,
        InputProductSearchComponent,
        ProductCategoryColorPipe,
        InfoHeaderComponent,
        ShoppingCartComponent,
        FoodRouteComponent,
        RouteEditorComponent,
        ShelfComponent,
        EditorNodeCornersComponent,
        HomeComponent,
        ShoppingListComponent,
        ProductCategoryNamePipe,
        NotificationComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
