import { Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { DiscounterRoom, InteriorType, Tile, ToolType } from 'src/app/models/discounter-room';
import { SimpleProduct } from 'src/app/components/input-product-search/input-product-search.component';
import { ProductsService } from 'src/app/services/products.service';
import { Shelf } from 'src/app/models/shelf';
import { LocalDataService } from 'src/app/services/local-data.service';
import { Observable, Subject } from 'rxjs';
import { Product, ProductCategory } from 'src/app/models/product';
import { FoodRouteService } from 'src/app/services/food-route.service';
export enum EditorMode {
    CONSTRUCT_MODE = 0,
    NODE_CORNER_MODE = 1,
    PRODUCT_MODE = 2,
}

@Component({
    selector: 'app-route-editor',
    templateUrl: './route-editor.component.html',
    styleUrls: ['./route-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteEditorComponent {
    isDraw = false;
    isDelete = false;
    selectedTool?: InteriorType;
    selectedNodeTool?: ToolType;
    discounter: DiscounterRoom = {
        width: 50,
        height: 50,
        enviromentTiles: new Map(),
    };

    currEditorMode = EditorMode.CONSTRUCT_MODE;
    editorMode = EditorMode;
    tileType = InteriorType;
    toolType = ToolType;

    products$ = new Observable<Product[]>();
    selectedShelfProduct?: Tile;
    selectedShelf?: Shelf[];
    multiSelectShelfs = false;

    changeDiscounter = new Subject<DiscounterRoom>();

    SAVE_KEY = 'DISCOUNTER_ROUTE_MAP';

    MOCK_MAP_CART_DATA =
        '[["MILCHPRODUKT",[{"id":"64e3775a054fe","name":"Butter","category":"MILCHPRODUKT","price":"2.29","cnt":1,"check":false}]],["KONSERVE",[{"id":"64e3870b9262f","name":"Senf","category":"KONSERVE","price":"1.29","cnt":1,"check":false}]],["GEMUSE",[{"id":"4","name":"Möhren","category":"GEMUSE","price":1.49,"cnt":1,"check":false}]],["GETRANK",[{"id":"8","name":"Bier Sternburg","category":"GETRANK","price":0.5,"cnt":3,"check":false}]]]';
    //'[["MILCHPRODUKT",[{"id":"64e3775a054fe","name":"Butter","category":"MILCHPRODUKT","price":"2.29","cnt":1,"check":false}]]]';
    shoppingCarMock: Map<ProductCategory, Product[]>;

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Shift') {
            this.multiSelectShelfs = true;
        }
    }

    @HostListener('window:keyup', ['$event'])
    handleKeyUp(event: KeyboardEvent) {
        if (event.key === 'Shift') {
            this.multiSelectShelfs = false;
        }
    }

    constructor(
        private productService: ProductsService,
        private localDataService: LocalDataService,
        private foodRouteService: FoodRouteService
    ) {
        this.initSavedData();
        this.products$ = productService.getProductList();

        //TEST MOCK DATA FOR FOOD ROUTING
        this.shoppingCarMock = new Map<ProductCategory, Product[]>(JSON.parse(this.MOCK_MAP_CART_DATA));

        this.foodRouteService.recognizeFoodRoute(this.shoppingCarMock, this.discounter.enviromentTiles).subscribe(routeWayTiles => {
            console.log(routeWayTiles);
            this.discounter.enviromentTiles = routeWayTiles;

            /*this.discounter.enviromentTiles = routeWayTiles;
            routeWayTiles.forEach((tile) => {
              if (tile.type === this.tileType.WAY) {
                //console.log(tile.isMarkRoute);
              }
            });*/
        });
    }

    initSavedData(): void {
        const saveDiscounterMap = this.localDataService.getData(this.SAVE_KEY);

        if (saveDiscounterMap) {
            this.discounter.enviromentTiles = new Map(JSON.parse(saveDiscounterMap));
            console.log(this.discounter.enviromentTiles);
        }
    }

    onAddProduct(simpleProduct: SimpleProduct): void {
        if (simpleProduct.id) {
            this.productService.getProductById(simpleProduct.id).subscribe(product => {
                if (this.selectedShelfProduct) {
                    //this.selectedShelfProduct.shelf = product;
                }
            });
        }
    }

    startDraw(x: number, y: number): void {
        this.isDraw = true;
        this.draw(x, y);
    }

    stopDraw(x: number, y: number): void {
        this.draw(x, y);
        this.isDraw = false;
    }

    draw(x: number, y: number): void {
        if (this.currEditorMode === EditorMode.CONSTRUCT_MODE) {
            if (this.isDraw && this.selectedTool !== undefined && !this.isDelete) {
                const id = x + '-' + y;
                const i = this.getIndexByCoords(x, y);
                const interiorTile: Tile = {
                    id: id,
                    index: i,
                    x: x,
                    y: y,
                    type: this.selectedTool,
                    distance: 0,
                };
                console.log(interiorTile);
                this.discounter.enviromentTiles.set(id, interiorTile);
            }

            if (this.isDraw && this.isDelete) {
                this.discounter.enviromentTiles.delete(x + '-' + y);
            }
        }
    }

    changeProductMode(type: string): void {}

    selectTool(toolType?: InteriorType): void {
        if (toolType !== this.selectedTool) {
            this.selectedTool = toolType;
        } else {
            this.isDelete = false;
            this.selectedTool = undefined;
        }
    }

    selectToolNode(tool: ToolType): void {
        this.selectedNodeTool = tool;
    }

    selectDeleteTool(): void {
        this.isDelete = true;
        this.selectedTool = undefined;
    }

    setInteriorTile(x: number, y: number): void {
        if (this.isDraw) {
            const interiorTile = {
                index: this.getIndexByCoords(x, y),
                x: x,
                y: y,
                type: 2,
            };
        }
    }

    setProductToShelf(tile: Tile): void {
        if (this.currEditorMode === EditorMode.PRODUCT_MODE) {
            this.selectedShelfProduct = { ...tile };
            const shelf = tile.shelf
                ? { ...tile.shelf }
                : ({
                      id: tile.id,
                      position: {
                          x: tile.x,
                          y: tile.y,
                      },
                      category: ProductCategory.EMPTY,
                  } as Shelf);

            if (this.multiSelectShelfs) {
                this.selectedShelf?.push(shelf);
            } else {
                this.selectedShelf = [shelf];
            }
        }
    }

    get discounterTiles(): Observable<DiscounterRoom> {
        return this.changeDiscounter.asObservable();
    }

    multiSelectionKey(event: KeyboardEvent) {
        if (event.key === 'Shift') {
            console.log('Shift-Taste wurde gedrückt.');
            this.multiSelectShelfs = true;
        } else {
            this.multiSelectShelfs = false;
        }
    }

    changeShelf(shelf: Shelf[]): void {
        if (this.selectedShelfProduct && this.selectedShelfProduct.id) {
            const id = this.selectedShelfProduct.id;
            const tile = this.discounter.enviromentTiles.get(id);
            if (shelf.length === 1) {
                if (tile) {
                    tile.shelf = shelf[0];
                }
            } else {
                shelf.forEach(shelf => {
                    this.discounter.enviromentTiles.forEach(tile => {
                        if (tile.id === shelf.id) {
                            tile.shelf = shelf;
                        }
                    });
                });
            }
        }
    }

    isTileSelected(x: number, y: number): Tile | undefined {
        return this.discounter.enviromentTiles.get(x + '-' + y);
    }

    isTileInMultiSelection(tile: Tile): boolean {
        const selection = this.selectedShelf?.filter(shelf => shelf.id === tile.id);
        return selection?.length ? true : false;
    }

    setEditorMode(selectedEditorMode: EditorMode): void {
        this.currEditorMode = selectedEditorMode;
        if (this.currEditorMode !== EditorMode.PRODUCT_MODE) {
            this.selectedShelfProduct = undefined;
        }
    }

    saveDiscount(): void {
        const map = Array.from(this.discounter.enviromentTiles.entries());
        this.localDataService.setData(this.SAVE_KEY, JSON.stringify(map));
    }

    drawActivate(): void {
        this.isDraw = true;
    }

    drawStop(): void {
        this.isDraw = false;
    }

    getIndexByCoords(x: number, y: number): number {
        return x + this.discounter.width * y;
    }
}
