import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { DayShoppingList, ShoppingList, WeekShoppingList } from '../../models/shopping-list';
import { Product } from 'src/app/models/product';
import { tap } from 'rxjs';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';

@Component({
    selector: 'app-shopping-list',
    templateUrl: './shopping-list.component.html',
    styleUrls: ['./shopping-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShoppingListComponent {
    shoppingCart$ = new Observable<WeekShoppingList | DayShoppingList>();

    shoppingList$ = new Observable<ShoppingList | null>();
    isWeekShoppingCart = false;

    productList?: Product[];
    weekProductList?: Product[][];
    currOpenDayIndex = 0;

    constructor(
        private router: Router,
        private shoppingCartService: ShoppingCartService,
        private productsService: ProductsService
    ) {
        this.shoppingList$ = this.shoppingCartService.getSelectedShoppingList().pipe(
            tap((selectedSHoppingList: ShoppingList | null) => {
                if (selectedSHoppingList?.productList) {
                    this.currOpenDayIndex = -1;
                }

                if (selectedSHoppingList === null) {
                    this.router.navigateByUrl('home');
                }
            })
        );

        this.productsService.onNewGeneratedProduct().subscribe(simpleProduct => {
            this.shoppingCartService.addProductByIdAndDayIndex(simpleProduct.id!, this.currOpenDayIndex, 1);
        });
    }

    toggleDay(index: number): void {
        this.currOpenDayIndex = index;
    }

    generateShoppingLink(): void {
        this.shoppingCartService.getSelectedShoppingList().subscribe(shoppinglist => {
            if (shoppinglist)
                this.shoppingCartService.generateShoppingLink(shoppinglist).subscribe(data => {
                    console.log(data);
                });
        });
    }
}
