import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, tap, timer } from 'rxjs';
import { Notification, NotificationAction, NotificationDuration, NotificationTyp } from 'src/app/models/notification';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
    notification$ = new Observable<Notification | null>();

    constructor(
        private notificationService: NotificationService,
        private destroyRef: DestroyRef
    ) {
        this.notification$ = this.notificationService.onIncomingNotifications().pipe(
            tap(notification => {
                if (notification?.duration) {
                    const removeTime = notification.duration * 1000;
                    timer(removeTime).subscribe(() => {
                        notification.isClose = true;
                        timer(400)
                            .pipe(takeUntilDestroyed())
                            .subscribe(() => {
                                this.notificationService.remove();
                            });
                    });
                }
            })
        );
    }

    executeAction(notification: Notification): void {
        notification.isClose = true;
        timer(300)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                if (notification.action) {
                    if (notification.action === NotificationAction.CLOSE) {
                        this.notificationService.remove();
                    }

                    if (notification.action === NotificationAction.COPY && notification.copyMsg) {
                        navigator.clipboard.writeText(notification.copyMsg);
                        this.notificationService.remove();
                        this.notificationService.notify({
                            type: NotificationTyp.INFO,
                            msg: 'Link zwischengespeichert!',
                            duration: NotificationDuration.SHORT,
                        });
                    }
                }
            });
    }

    removeNotification(): void {
        this.notificationService.remove();
    }
}
