import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { rotate90 } from 'src/app/animations/rotate-90.animation';
import { toggleSubMenu } from 'src/app/animations/toggle-sub-menu.animation';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    animations: [toggleSubMenu, rotate90],
})
export class NavigationComponent {
    isSubMenuOpen = false;

    constructor(
        private userService: UserService,
        private shoppingCartService: ShoppingCartService
    ) {}

    logout(): void {
        this.shoppingCartService.setShoppingCartSelected(false);
        this.userService.logout();
    }

    get isShoppingCartSelected(): Observable<boolean> {
        return this.shoppingCartService.isShoppingListSelected;
    }

    toggleSubMenu(): void {
        this.isSubMenuOpen = !this.isSubMenuOpen;
    }
}
