import { Component } from '@angular/core';
import { UserService } from './services/user.service';
import { Observable } from 'rxjs';
import { ShoppingCartService } from './services/shopping-cart.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'FoodRoute';
    userLogedIn$ = new Observable<boolean>();
    constructor(
        private userService: UserService,
        private shoppingCartService: ShoppingCartService,
        private activeRoute: ActivatedRoute
    ) {
        this.userService.tryAutoLogin();
        this.userLogedIn$ = userService.isUserLoggedIn;
    }
}
