import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { Product, ProductCategory } from 'src/app/models/product';
import { DayShoppingCart, WeekShoppingCart } from 'src/app/models/shopping-cart';

@Component({
    selector: 'app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShoppingCartComponent {
    shoppingCartData$ = new Observable<Map<ProductCategory, Product[]>>();

    shoppingCart$ = new Observable<WeekShoppingCart | DayShoppingCart>();
    isWeekShoppingCart = false;

    checked = false;
    constructor(private shoppingCartService: ShoppingCartService) {
        this.shoppingCartData$ = this.shoppingCartService.getSelectedShoppingListProducts().pipe(
            map(productList => {
                const cloneProductList = JSON.parse(JSON.stringify(productList)) as Product[];
                const cartMap = new Map<ProductCategory, Product[]>();
                if (cloneProductList) {
                    cloneProductList.forEach(product => {
                        const cat = product.category;
                        if (!cartMap.get(cat)) {
                            cartMap.set(cat, [product]);
                        } else {
                            let productAlreadyExist = false;
                            cartMap.get(cat)?.map(persistProduct => {
                                if (persistProduct.id === product.id && persistProduct.cnt && product.cnt) {
                                    persistProduct.cnt += product.cnt;
                                    productAlreadyExist = true;
                                }
                                return persistProduct;
                            });

                            if (!productAlreadyExist) {
                                cartMap.get(cat)?.push(product);
                            }
                        }
                    });
                }
                return cartMap;
            })
        );
    }

    toggleProduct(product: Product): void {
        this.checked = !this.checked;
        product.check = !product.check;
        //this.shoppingCartService.toggleProductCheckInSelectedShoppingList(product);
    }

    trackByIndex(index: number, item: any): number {
        return index;
    }
}
