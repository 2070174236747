import { Pipe, PipeTransform } from '@angular/core';
import { CategoryName, ProductCategory } from '../models/product';

@Pipe({
    name: 'productCategoryName',
})
export class ProductCategoryNamePipe implements PipeTransform {
    transform(category: ProductCategory): string {
        return CategoryName[ProductCategory[category]] || '';
    }
}
