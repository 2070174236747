export const environment = {
    production: true,
    homeUrl: 'https://www.FoodRoute.Querbeet-Media.de',
    apiUrl: 'https://www.test.querbeet-media.de/api',
    localDataKeyStore: {
        userKey: 'QM_SHOPPING_USER_KEY',
        selectedShoppingListKey: 'QM_SHOPPING_SELECTED_SHOPPING_LIST_KEY',
        shoppingListCollectionKey: 'QM_SHOPPING_LIST_KEY',
    },
};
