import { Pipe, PipeTransform } from '@angular/core';
import { CategoryIcon, ProductCategory } from '../models/product';

@Pipe({
    name: 'productCategoryIcon',
})
export class ProductCategoryIconPipe implements PipeTransform {
    transform(category: ProductCategory): string {
        return CategoryIcon[ProductCategory[category]] || '';
    }
}
