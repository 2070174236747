<div class="section-login">
    <app-logo></app-logo>
    <form [formGroup]="loginForm" (submit)="onSubmit()">
        <div class="row">
            <span>
                <label>Benutzername</label>
            </span>
            <input formControlName="username" type="text" name="username" placeholder="max@mustermann.de" />
        </div>
        <div class="row">
            <span>
                <label>Passwort</label>
            </span>
            <input formControlName="password" type="password" name="pw" placeholder="Passwort" />
        </div>
        <div class="row">
            <button class="btn-primary" type="submit"><i class="fa fa-right-to-bracket"></i> Login</button>
        </div>
    </form>
</div>
