import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ProductsService } from 'src/app/services/products.service';
import { SimpleProduct } from '../input-product-search/input-product-search.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Product, ProductCategory } from 'src/app/models/product';

export interface Category {
    id: ProductCategory;
    name: string;
}

export interface NewProduct {
    n: string;
    ca: string;
    c: number;
    p: number;
}

@Component({
    selector: 'app-add-product-panel',
    templateUrl: './add-product-panel.component.html',
    styleUrls: ['./add-product-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductPanelComponent implements OnInit {
    @Output() onAddProduct = new EventEmitter<Product>();
    newProduct$ = new Observable<SimpleProduct | null>();
    productForm: FormGroup;
    categories$: Observable<Category[]> = new Observable();
    count = 1;

    constructor(
        private productsService: ProductsService,
        private fb: FormBuilder
    ) {
        this.productForm = fb.group({
            n: ['', Validators.required],
            ca: ['', Validators.required],
            p: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.newProduct$ = this.productsService.isShowNewProductPanel().pipe(
            tap((product: SimpleProduct | null) => {
                if (product) {
                    this.productForm?.patchValue({ n: product.n });
                }
            })
        );
        this.categories$ = this.productsService.getCategories();
    }

    generateProduct(): void {
        if (this.productForm.valid) {
            const newProduct = this.productForm.getRawValue() as NewProduct;
            newProduct.c = this.count;
            this.productsService.generateProduct(newProduct).subscribe(product => {
                if (product) {
                    //this.productsService.generatedProduct.next(product);
                    this.productForm.reset();
                    this.close();
                    //this.onAddProduct.emit(product);
                }
            });
        }
    }

    close(): void {
        this.productsService.hideNewProductPanel();
    }
}
