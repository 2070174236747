<div class="content">
    <h2 class="title">Wochen Einkaufszettel</h2>
    <ng-container *ngIf="weekShoppingList$ | async as weekShoppingList">
        <ul>
            <li *ngFor="let dayShoppingList of weekShoppingList.week; let i = index" class="list-week-days">
                <app-day-shopping-list (toggleDay)="toggleDay($event)" [currOpenDayIndex]="currOpenDayIndex" [dayIndex]="i" [dayShoppingList]="dayShoppingList.shoppingList"></app-day-shopping-list>
            </li>
        </ul>
    </ng-container>
</div>
